import { type IMenuItem } from '@/types/menu.type';
import { mergeSpec } from '@utils/ramda.utils';
import { usePath } from 'hookrouter';
import * as R from 'ramda';

const useRoute = (config): IMenuItem => {
  const path = usePath();
  const route = R.pipe(
    R.values,
    R.find(
      R.ifElse(
        R.prop('match'),
        R.propSatisfies(R.pipe(R.match(R.__, path), R.length), 'match'),
        R.propEq('path', path),
      ),
    ),
    R.when(
      R.prop('match'),
      mergeSpec({
        params: R.pipe(R.prop('match'), R.match(R.__, path), R.prop('groups')),
      }),
    ),
  )(config);
  return route;
};

export default useRoute;
