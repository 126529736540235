import { SadaBtn } from './styles';
import { type Node } from 'react';
import { ButtonProps } from 'antd';
import * as R from 'ramda';

type Props = ButtonProps & {
  secondary: boolean,
  disabled: boolean,
  outline: boolean,
  icon: Node,
  iconright: boolean,
  sm: boolean,
  sub: boolean,
  highlight: boolean,
  danger: boolean,
};

const KBtn = ({ children, ...props }: Props) => (
  <SadaBtn
    size={'large'}
    {...R.map(R.when(R.pipe(R.type, R.equals('Boolean')), (v) => +!!v))(props)}
    type={props.secondary ? 'link' : props.outline ? 'default' : 'primary'}
  >
    {children}
  </SadaBtn>
);

export default KBtn;
