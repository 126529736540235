import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase';


const useAuth = (): [firebase.User, boolean] => {
  const result = useAuthState(firebase.auth());

  return [...result];
};

export default useAuth;
