import { SadaApplicantQuestionsFormNav } from './styles';
import type IService from '@/types/service.type';
import { type IOrganization } from '@/types/organization.type';
import { Image, Skeleton, Space } from 'antd';
import React from 'react';
import Typography from '@components/typography';
import ApplicantQuestionsFormNavStepper from './applicant-questions-form-nav-stepper';
import { UIFlexBox } from '@components/ui/layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import KBtn from '@components/k-btn';

type Props = {
  service: IService,
  organization: IOrganization,
  loading: Boolean,
  formId: String,
  onStepClick: Function,
};

const ApplicantQuestionsFormNav = ({
  service = {},
  organization,
  loading,
  formId,
  onStepClick,
}: Props) => {
  const steps = service?.steps;
  return (
    <UIFlexBox position="relative" width="100%" maxWidth={450}>
      <PerfectScrollbar>
        <SadaApplicantQuestionsFormNav>
          {loading ? (
            <Space direction="vertical" size={24}>
              <Skeleton
                title={false}
                paragraph={false}
                avatar={{ size: 64, shape: 'square' }}
                active
              />
              <Skeleton title paragraph={false} avatar={false} active />
              <Skeleton
                title={false}
                paragraph={{ rows: 4 }}
                avatar={false}
                active
              />
            </Space>
          ) : (
            <Space direction="vertical" size={24}>
              <Image src={organization?.splash} />
              <Typography xxl bold>
                {service?.title}
              </Typography>
              <Typography sub md>
                {service?.description}
              </Typography>
              {steps && (
                <ApplicantQuestionsFormNavStepper
                  steps={steps}
                  formId={formId}
                  onStepClick={onStepClick}
                />
              )}
            </Space>
          )}
          <KBtn block onClick={onStepClick}>
            Get Started
          </KBtn>
        </SadaApplicantQuestionsFormNav>
      </PerfectScrollbar>
    </UIFlexBox>
  );
};

export default ApplicantQuestionsFormNav;
