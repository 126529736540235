import { FadeZoomIn } from '@components/ui/animations';
import { UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Row } from 'antd';

export const SadaContentCard = styled(FadeZoomIn)`
  border-radius: ${radiuses.lg};
  overflow: hidden;
  background: ${({ light }) => (light ? colors.white : colors.almost_white)};
  border: 1px solid ${({ light }) => (light ? colors.light_gray : colors.white)};
`;

export const SadaContentCardCover = styled(UIFlexBox)`
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 60%
    ),
    url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  height: 120px;
  color: ${colors.white};
  padding: ${spacings.md} ${spacings.xxl};
  align-items: flex-end;
`;

export const SadaContentCardHeader = styled(UIFlexBox)`
  padding: ${spacings.sm} ${spacings.xl};
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.light_gray};
`;

export const SadaContentCardData = styled(Row)`
  padding: ${spacings.xl} 0;
  .ant-col {
    padding: 0 ${spacings.xl};
    & + .ant-col {
      border-left: 1px solid ${colors.light_gray};
    }
  }
`;

export const SadaContentCardChildren = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '0' : spacings.xl)};
`;

export const SadaContentCardActions = styled.div`
  padding: 0;
`;
