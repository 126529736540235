import firebase from 'firebase';
import { customAlphabet } from 'nanoid/async';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import * as R from 'ramda';

export const login = async () => {
  await firebase.auth().signInWithPopup();
};
export const logout = async () => {
  await firebase.auth().signOut();
};

export const newId = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 12);

export const dateFilteredQuery = (key) => {
  const d = new Date();
  const dateRangeFilters = {
    'this-week': { start: startOfWeek(d), end: endOfWeek(d) },
    'last-7-days': {
      start: subDays(endOfDay(d), 100),
      end: subDays(endOfDay(d), 90),
    },
    'this-month': { start: startOfMonth(d), end: endOfMonth(d) },
    'last-30-days': { start: subDays(endOfDay(d), 30), end: endOfDay(d) },
  };

  return R.map((v) => firebase.firestore.Timestamp.fromDate(v))(
    dateRangeFilters[key],
  );
};
