import ContentCard from '@components/cards/content-card';
import { SadaServiceAppointmentCard } from './styles';
import type IService from '@types/service.type';
import Typography from '@components/typography';
import { Space } from 'antd';
import KBtn from '@components/k-btn';
import { navigate } from 'hookrouter';

type Props = IService & {};

const ServiceAppointmentCard = (props: Props) => {
  const { splash, id, title, description, duration } = props;
  return (
    <SadaServiceAppointmentCard xs={24} sm={12}>
      <ContentCard light cover={{ image: splash, title }}>
        <Space direction="vertical" size="large">
          <Space direction="vertical">
            <Typography>{description}</Typography>
            <Typography>
              <strong>Duration:</strong> {duration} minutes
            </Typography>
          </Space>
          <KBtn
            secondary
            block
            onClick={() => {
              navigate(`/apply/${id}`);
            }}
          >
            Book Appointment
          </KBtn>
        </Space>
      </ContentCard>
    </SadaServiceAppointmentCard>
  );
};

export default ServiceAppointmentCard;
