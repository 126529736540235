import KHeading from '@components/k-heading';
import { Col, Row, Space } from 'antd';
import { SadaDashboard } from './styles';
import UserCard from '@components/cards/user-card';
import useAuth from '@hooks/auth.hook';
import fsOrm from '@services/firestore.service';
import { navigate } from 'hookrouter';
import ActivityList from '@components/activity-list';
import firebase from 'firebase';
import MeetCardWrapper from '@components/meet-card-wrapper';
import ServicesList from './services-list';
import useFs from '@hooks/fs.hook';
import KCardList from '@components/lists/k-card-list';
import * as R from 'ramda';
import { getCaseContentString } from '@utils/case.utils';
import StatusTag from '@components/status-tag';
import { ReactComponent as EmptyCases } from '@/assets/images/empty-cases.svg';
import ShowMobile from '@components/show-mobile';
import HideMobile from '@components/hide-mobile';
import { startOfMinute, subMinutes } from 'date-fns';
import { getApptTimeStatus } from '@utils/appointment.utils';

const Dashboard = () => {
  const [user] = useAuth();
  const applicantRef = fsOrm.applicants(user?.email || 'null').ref();
  const [data, loading] = useFs({
    query: firebase
      .firestore()
      .collection('appointments')
      .where('applicant', '==', fsOrm.applicants(user.email).ref())
      .where('start', '>', subMinutes(startOfMinute(new Date()), 45))
      .orderBy('start', 'asc'),
    populate: ['service', 'employee'],
  });
  const query = firebase.firestore().collection('activity').where('applicant', '==', applicantRef);

  const topLeft = (
    <Space direction="vertical" size={16}>
      <UserCard user={user} />
      {user?.email && (
        <MeetCardWrapper applicant={user.email} emptyText="You have no upcoming appointments" />
      )}
    </Space>
  );
  return (
    <SadaDashboard>
      <Row gutter={40}>
        <Col sm={14}>
          <Space size={32} direction="vertical">
            <ShowMobile>{topLeft}</ShowMobile>
            <Space size="small" direction="vertical">
              <KHeading
                title="Upcoming Appointments"
                action="View all"
                onAction={() => {
                  navigate('/cases');
                }}
              />
              <KCardList
                config={{
                  title: R.ifElse(
                    R.path(['employee', 'name']),
                    R.path(['employee', 'name']),
                    R.path(['employee', 'email']),
                  ),
                  name: R.ifElse(
                    R.path(['employee', 'name']),
                    R.path(['employee', 'name']),
                    R.path(['employee', 'email']),
                  ),
                  photo: R.path(['employee', 'photo']),
                  subTitle: R.path(['service', 'title']),
                  content: getCaseContentString,
                  action: ({ status }) => <StatusTag flat {...status} />,
                  status: getApptTimeStatus,
                }}
                onClick={(id) => {
                  navigate(`/cases/${data.find(R.propEq('id', id))?.case?.id}`);
                }}
                emptyImage={<EmptyCases />}
                emptyText="No upcoming appointments"
                loading={loading}
                data={data}
              />
              <Space size="large" direction="vertical">
                <ServicesList />
              </Space>
            </Space>
          </Space>
        </Col>
        <Col xs={24} sm={10}>
          <Space direction="vertical" size={24}>
            <HideMobile>{topLeft}</HideMobile>
            <ActivityList query={query} />
          </Space>
        </Col>
      </Row>
    </SadaDashboard>
  );
};

export default Dashboard;
