import { Edit } from 'react-iconly';
import ContentCard from '../content-card';
import type IService from '@types/service.type';
import type ICase from '@types/case.type';
import { humanTimestamp } from '@utils/date.utils';

type Props = {
  service: [IService, Boolean],
  caseData: [ICase, Boolean],
  cover: Object,
};

const CaseDetailsCard = ({ service, caseData, cover }: Props) => {
  return (
    <ContentCard
      {...(cover ? { cover } : { title: ' ' })}
      icon={<Edit />}
      data={[
        [
          {
            label: 'Type',
            value: service?.[0]?.title,
            loading: service?.[1],
          },
          {
            label: 'Date Created',
            loading: caseData?.[1],
            value: humanTimestamp(caseData?.[0]?.created),
          },
        ],
        [],
      ]}
    />
  );
};

export default CaseDetailsCard;
