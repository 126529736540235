/* eslint-disable react/prop-types */
import {
  space,
  flexbox,
  color,
  layout,
  border,
  shadow,
  position,
  background,
  compose,
} from 'styled-system';
import styled from '@emotion/styled';

export const UIBox = styled.div`
  box-sizing: border-box;
  min-width: 0;
  .ant-space {
    display: flex;
  }
  ${compose(space, layout, color, border, shadow, position, background)}
`;

export const UIFlexBox = styled(UIBox)`
  display: flex;
  ${flexbox};
`;

export const UICenterBox = styled(UIFlexBox)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const UISpaceBox = styled(UIFlexBox)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
