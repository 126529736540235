export const applicantQuestionFormData = {
  title: 'Driver License Renewal',
  calendarId: 'c_t03hksg00e9bfjma3u5akvlrc0@group.calendar.google.com',
  caption: 'Consequat proident eiusmod dolore Lorem esse nostrud.',
  description:
    'At diam turpis augue sagittis felis viverra dictumst cursus penatibus porttitor dis integer curae ornare quis imperdiet elit risus praesent',
  steps: [
    {
      title: 'Risus egestas quam magnis',
      description:
        'Parturient dis quisque nulla nisi dignissim viverra scelerisque tincidunt massa',
      type: 'list',
      questions: [
        {
          caption: '',
          text: 'Varius viverra rhoncus?',
          id: 'T6CNIGIW25L0',
          type: 'text',
        },
        {
          options: [
            'Nam ligula imperdiet',
            'Auctor malesuada sed',
            'Quisque dis etiam',
          ],
          caption: 'Torquent libero lobortis molestie euismod dapibus eget',
          text: 'Elementum justo tortor magna?',
          type: 'select',
          id: 'RIC82SYJ5S03',
        },
        {
          caption:
            'Auctor pellentesque praesent hendrerit ultricies volutpat himenaeos',
          options: [
            'Porta feugiat turpis habitasse',
            'Dictum non cum',
            'Justo dui',
            'Tincidunt tempor scelerisque varius',
          ],
          text: 'Blandit sapien arcu laoreet?',
          type: 'multiselect',
          id: 'Q724IB3GUVQF',
        },
        {
          type: 'checkbox',
          caption: 'Risus maecenas ullamcorper felis fermentum sed et',
          id: 'WNAUBBX82C6L',
          text: 'Placerat nibh fringilla duis?',
        },
      ],
      id: 'TMCI3UEMOB1U',
      caption: '',
    },
    {
      questions: [
        {
          id: '1WDEUIY2D9ZR',
          text: 'Netus mattis facilisis adipiscing?',
          type: 'checkboxgroup',
          options: [
            'Quis dui consectetur',
            'Cras ridiculus class',
            'Sem libero metus posuere lorem cum',
            'Justo purus ac erat',
          ],
          caption: '',
        },
        {
          text: 'Volutpat mattis habitant taciti?',
          id: 'CUYUGD7ZA3M1',
          type: 'radio',
          options: [
            'Turpis facilisi sem',
            'Dignissim sed primis',
            'Pharetra inceptos convallis himenaeos',
          ],
          caption:
            'Tristique vulputate placerat natoque cubilia cursus sit proin nostra dictumst egestas dapibus interdum torquent',
        },
      ],
      description: 'Consectetur mollis ut nullam',
      caption: '',
      id: '86UCMCX986VG',
      type: 'list',
      title: 'Diam primis consectetur auctor curae feugiat congue',
    },
    {
      caption: '',
      title: 'Upload resume',
      type: 'file',
      description: 'Class sit vulputate phasellus luctus natoque habitasse',
      id: 'RT4QQOYEQIWD',
    },
  ],
};

export const lastStep = {
  id: 'complete',
  type: 'complete',
  title: 'Submit Application',
  description: 'Schedule an appointment and create an account',
};
