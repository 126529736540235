import { UICenterBox, UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import styled from '@emotion/styled';

export const SadaLogin = styled(UICenterBox)`
  background: ${colors.almost_white};
`;

export const SadaLoginBox = styled(UIFlexBox)`
  justify-content: center;
  align-items: center;
  width: 500px;
  max-width: 100vw;
  padding: 48px 96px 74px;
  background: ${colors.white};
  border-radius: ${radiuses.md};
  .ant-space {
    width: 100%;
  }
`;
