import { humanTime } from '@utils/date.utils';
import { Col, Row, Spin } from 'antd';
import KTimeButton from './k-time-button';
import { SadaKTimePicker } from './styles';
import useAvailability from '@hooks/availability.hook';
import type IService from '@types/service.type';
import * as R from 'ramda';
import { selectLeastBusy } from '@utils/schedule.utils';

type Props = {
  service: IService,
  date: Date,
};

const KTimePicker = ({ service, date, selected, onSelect }: Props) => {
  const [data, loading] = useAvailability(service?.id, date);
  return (
    <SadaKTimePicker>
      <Spin tip="Loading..." spinning={loading}>
        <Row gutter={8}>
          {data.map((slot) => (
            <Col xs={8} key={slot.start}>
              <KTimeButton
                onClick={() => {
                  onSelect({
                    start: slot.start,
                    end: slot.end,
                    employee: selectLeastBusy(data)(slot),
                  });
                }}
                active={R.equals(slot.start, selected)}
                disabled={!slot.employees.length}
              >
                {humanTime(new Date(slot.start))}
              </KTimeButton>
            </Col>
          ))}
        </Row>
      </Spin>
    </SadaKTimePicker>
  );
};

export default KTimePicker;
