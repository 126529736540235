import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { sizes } from '@constants/theme/sizes.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Input } from 'antd';

export const SadaKInput = styled(Input)`
  .ant-input-suffix {
    margin-right: -${spacings.xss};
  }
  /* height: ${sizes.md};
  border-radius: ${radiuses.md};
  background: ${colors.almost_white};
  border-color: ${colors.almost_white};
  padding: ${spacings.xs} ${spacings.md};
  input {
    background-color: ${colors.almost_white};
    &::placeholder {
      color: ${colors.dark_gray};
    }
  }
  .ant-input-prefix {
    margin-right: ${spacings.md};
  }
  svg {
    transition: color 0.3s ease;
    color: ${colors.dark_gray};
  }
  &.ant-input-affix-wrapper-focused, &:focus {
    box-shadow: none;
    border: 1px solid ${colors.blue};
    svg {
      color: ${colors.blue};
    }
  } */
`;
