import { format, isValid, setMinutes, setHours, setDay } from 'date-fns/fp';
import { isPast } from 'date-fns';
import * as R from 'ramda';
import firebase from 'firebase';

const humanFormat = 'MM/dd/yyyy';
const humanTimeFormat = 'h:mm a';
const humanDateTimeFormat = 'MM/dd/yyyy h:mm a';

const valiDate = (fn) => R.pipe(R.when(isValid, fn));

export const humanDate = valiDate(format(humanFormat));

export const humanTime = valiDate(format(humanTimeFormat));
export const humanDateTime = valiDate(format(humanDateTimeFormat));

export const fromTimestamp = (timestamp: firebase.firestore.Timestamp) =>
  timestamp?.toDate && timestamp?.toDate();

export const humanTimestamp = R.pipe(fromTimestamp, humanDate);
export const humanTimestampTime = R.pipe(fromTimestamp, humanTime);

export const humanTimestampDateTime = R.pipe(fromTimestamp, humanDateTime);
export const minutesToTime = (v) =>
  R.pipe(
    setHours(Math.floor(v / 60)),
    setMinutes(v % 60),
    format(humanTimeFormat),
  )(new Date());

export const getDay = (v) => R.pipe(setDay(v), format('EEEE'))(new Date());
export const getJustDate = format('yyyy-MM-dd');

export const getDisabledDate = (schedule) => (date) => {
  return isPast(date) || !schedule?.[date.getDay()]?.active;
};
