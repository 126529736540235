import { SadaLogo } from './styles';
import useOrg from '@hooks/org.hook';

const Logo = () => {
  const [org] = useOrg();
  return (
    <SadaLogo>{org && <img src={org.logo_small} alt="VAST Logo" />}</SadaLogo>
  );
};

export default Logo;
