import UserMenu from '@components/user-menu';
import useRoute from '@hooks/route.hook';
import KHeading from '@components/k-heading';
import { SadaHeader, SadaHeaderDesktop, SadaHeaderMobile } from './styles';
import applicantRoutesConfig from '@applicant/constants/routes.config';
import { navigate } from 'hookrouter';
import Logo from '@components/menu/logo';
import MobileMenuButton from '@components/mobile-menu-button';

const Header = () => {
  const current = useRoute(applicantRoutesConfig);

  return (
    <SadaHeader align="middle">
      <SadaHeaderDesktop>
        <KHeading xl title={current?.label} />
        <UserMenu
          onEdit={() => {
            navigate('/account');
          }}
        />
      </SadaHeaderDesktop>
      <SadaHeaderMobile>
        <MobileMenuButton />
        <Logo />
        <UserMenu
          onEdit={() => {
            navigate('/account');
          }}
        />
      </SadaHeaderMobile>
    </SadaHeader>
  );
};

export default Header;
