export const colors = {
  white: '#FFFFFF',
  almost_white: '#F5F5F5',
  light_gray: '#E5E5E5',
  dark_gray: '#838383',
  almost_black: '#131313',
  black: '#000000',
  blue: '#0069CB',
  blue_50: 'rgba(0, 82, 159, 0.5)',
  blue_10: 'rgba(0, 82, 159, 0.1)',
  green: '#649f33',
  orange: '#ec7d4a',
  red: '#B83939',
};

export const statusColors = {
  danger: colors.red,
  warning: colors.orange,
  success: colors.green,
  neutral: colors.blue,
};
