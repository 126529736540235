import { SadaApplicantCard } from './styles';
import { Col, Row, Skeleton, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { UICenterBox, UIFlexBox } from '@components/ui/layout';
import Typography from '@components/typography';
import type IApplicant from '@types/applicant.type';
import { getInitials } from '@utils/user.utils';
import KHeading from '@components/k-heading';
import { Edit } from 'react-iconly';

type Props = {
  applicant: IApplicant,
  loading: Boolean,
  onAction: Function,
  title: String,
};

const ApplicantCard = ({
  applicant,
  loading,
  title,
  onAction = () => {},
}: Props) => (
  <SadaApplicantCard>
    {title && (
      <KHeading
        title="Your Informartion"
        md
        icon={<Edit />}
        onAction={onAction}
      />
    )}
    <Row>
      <Col xs={24} sm={7}>
        <UICenterBox px={4}>
          {loading ? (
            <Skeleton
              avatar={{ size: 72 }}
              paragraph={false}
              title={false}
              active
            />
          ) : (
            <Avatar src={applicant?.photo} size={72}>
              {getInitials(applicant?.name)}
            </Avatar>
          )}
        </UICenterBox>
      </Col>
      <Col xs={24} sm={17}>
        <Space direction="vertical">
          <UIFlexBox justifyContent="space-between">
            <Typography>Name</Typography>
            <Typography sub loading={loading}>
              {applicant?.name}
            </Typography>
          </UIFlexBox>
          <UIFlexBox justifyContent="space-between">
            <Typography>Phone</Typography>
            <Typography sub loading={loading}>
              {applicant?.phone}
            </Typography>
          </UIFlexBox>
          <UIFlexBox justifyContent="space-between">
            <Typography>Email</Typography>
            <Typography sub loading={loading}>
              {applicant?.email}
            </Typography>
          </UIFlexBox>
        </Space>
      </Col>
    </Row>
  </SadaApplicantCard>
);

export default ApplicantCard;
