import { colors } from '@constants/theme/colors.config';
import { Dropdown, Menu } from 'antd';
import { CloseSquare, EditSquare, MoreSquare, TickSquare } from 'react-iconly';
import { SadaAppointmentMenu } from './styles';

type Props = { response: String, employee: any, user: any };

const AppointmentMenu = ({
  response,
  employee,
  user,
  onAction = () => {},
}: Props) => {
  const isCurrentUser = user && user?.email === employee?.id;
  return (
    <Dropdown
      placement="bottomRight"
      trigger="click"
      overlay={
        <SadaAppointmentMenu onClick={onAction}>
          <Menu.Item disabled={!isCurrentUser} key="accepted">
            <TickSquare
              primaryColor={isCurrentUser ? colors.blue : colors.light_gray}
            />
            Accept
          </Menu.Item>
          <Menu.Item disabled={!isCurrentUser} key="declined">
            <CloseSquare
              primaryColor={isCurrentUser ? colors.red : colors.light_gray}
            />
            Decline
          </Menu.Item>
          <Menu.Item key="edit">
            <EditSquare primaryColor={colors.orange} />
            Reschedule
          </Menu.Item>
        </SadaAppointmentMenu>
      }
    >
      <MoreSquare />
    </Dropdown>
  );
};

export default AppointmentMenu;
