import { colors } from '@constants/theme/colors.config';
import { fontSizes } from '@constants/theme/typography.config';
import styled from '@emotion/styled';
import { Timeline } from 'antd';

export const SadaApplicantQuestionsFormNavStepperItem = styled(Timeline.Item)`
  .ant-avatar-string {
    font-size: ${fontSizes.md};
  }
  .ant-timeline-item-tail {
    ${({ finished }) => (finished ? ` border-left-color: ${colors.blue};` : '')}
    border-radius: 5px;
    height: calc(100% - 46px);
    top: 28px;
    ${({ last }) => (last === 'last' ? `display: none;` : '')}
  }
`;
