import { colors } from '@constants/theme/colors.config';
import { fontSizes, lineSizes } from '@constants/theme/typography.config';
import styled from '@emotion/styled';
import { UICenterBox } from '@components/ui/layout';
import { spacings } from '@constants/theme/spacing.config';

export const SadaLogo = styled(UICenterBox)`
  font-size: ${fontSizes.sm};
  line-height: ${lineSizes.sm};
  font-weight: 600;
  color: ${colors.primary};
  padding: ${spacings.xl};
  img {
    max-width: 80%;
  }
  flex-grow: 0;
  height: auto;
`;
