import { FadeDropIn } from '@components/ui/animations';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import Avatar from 'antd/lib/avatar/avatar';

export const SadaApplicantQuestionsFormNavStepper = styled(FadeDropIn)`
  margin-top: ${spacings.xxxl};
  .ant-timeline-item-content {
    padding-left: ${spacings.md};
  }
`;

export const IndexAvatar = styled(Avatar)``;
