import KHeading from '@components/k-heading';
import ServiceAppointmentCard from '@components/service-appointment-card';
import useList from '@hooks/list.hook';
import { Row } from 'antd';
import { SadaServicesList } from './styles';

const ServicesList = () => {
  const [services = []] = useList('services');
  return (
    <SadaServicesList>
      <KHeading title="Our Services" />
      <Row gutter={20}>
        {services.map((service) => (
          <ServiceAppointmentCard key={service.id} {...service} />
        ))}
      </Row>
    </SadaServicesList>
  );
};

export default ServicesList;
