export const caseStatuses = {
  invited: { color: 'warning', text: 'Invite sent' },
  active: { color: 'success', text: 'Active' },
  pending: { color: 'warning', text: 'Pending' },
  booked: { color: 'success', text: 'Booked' },
  cancelled: { color: 'danger', text: 'Cancelled' },
  declined: { color: 'danger', text: 'Declined' },
  completed: { color: 'neutral', text: 'Completed' },
  accepted: { color: 'neutral', text: 'Accepted' },
  rescheduled: { color: 'warning', text: 'Rescheduled' },
};
