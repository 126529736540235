import { SadaApplicantQuestionsForm } from './styles';
import type IService from '@/types/service.type';
import type IOrganization from '@/types/organization.type';
import ApplicantQuestionsFormNav from './applicant-questions-form-nav';
import ApplicantQuestionsFormStep from './applicant-questions-form-step';
import { useRef } from 'react';

type Props = {
  service: IService,
  organization: IOrganization,
  loading: Boolean,
  formId: String,
  onFinish: Function,
  action: Function,
};

const ApplicantQuestionsForm = ({
  service,
  loading,
  organization,
  action,
}: Props) => {
  const steps = service?.steps || [];

  const ref = useRef();
  return (
    <SadaApplicantQuestionsForm>
      <ApplicantQuestionsFormNav
        {...{ service, loading, organization }}
        onStepClick={() => {
          ref.current.scrollIntoView({ behavior: 'smooth' });
        }}
      />
      <ApplicantQuestionsFormStep
        ref={ref}
        steps={steps}
        action={action}
        service={service}
      />
    </SadaApplicantQuestionsForm>
  );
};

export default ApplicantQuestionsForm;
