import firebase from 'firebase';
import { firebaseConfig } from '@/constants/firebase.config';
import type IService from '@types/service.type';
import * as R from 'ramda';
import { message as Message } from 'antd';

const { Timestamp } = firebase.firestore;

type Methods = 'create' | 'update' | 'set' | 'ref';
class FirestoreService {
  collections = [
    'services',
    'applicants',
    'cases',
    'employees',
    'appointments',
    'forms',
    'activity',
  ];
  methods = ['create', 'update', 'set'];
  services: (
    id: String,
  ) => {
    [key: Methods]: (
      IService,
      String,
    ) => Promise<firebase.firestore.DocumentReference<IService>>,
  };

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.createMethods();
    this.createCollections();
  }

  createMethods = () => {
    this.methods.forEach((method) => {
      this[method] = (collection, id) => async (data = {}, message) => {
        try {
          const ref = await this[`_${method}`](
            collection,
            id,
          )(
            R.pipe(
              R.reject(R.equals(undefined)),
              R.assoc('timestamp', Timestamp.now()),
            )(data),
          );
          if (message) {
            Message.success(message);
          }
          return ref;
        } catch (error) {
          Message.error(error.message);
        }
      };
    });
  };

  createCollections = () => {
    this.collections.forEach((collection) => {
      this[collection] = this.wrapper(collection);
    });
  };

  _create = (collection, id) => async (data) => {
    const key = id || data?.id;
    key
      ? await this.set(
          collection,
          key,
        )({ ...data, created: firebase.firestore.Timestamp.now() })
      : await this.db
          .collection(collection)
          .add({ ...data, created: firebase.firestore.Timestamp.now() });
    return this.db.collection(collection).doc(key);
  };

  _set = (collection, id) => (data) =>
    this.db.collection(collection).doc(id).set(data);

  _update = (collection, id) => (data) =>
    this.db.collection(collection).doc(id).update(data);

  _ref = (collection, id) => () => this.db.collection(collection).doc(id);

  wrapper = (collection) => (id) => {
    return {
      set: this.set(collection, id),
      update: this.update(collection, id),
      create: this.create(collection, id),
      ref: this._ref(collection, id),
    };
  };

  setStatus = async (status, ref, user) => {
    const newStatus = {
      timestamp: Timestamp.now(),
      status,
      ...(user ? { employee: this.employees(user).ref() } : {}),
    };
    await ref.collection('statuses').add(newStatus);
    await ref.update({ status: newStatus });
  };

  addComment = async (text, ref, user) => {
    const newComment = {
      timestamp: Timestamp.now(),
      text,
      ...(user ? { employee: this.employees(user).ref() } : {}),
    };
    await ref.collection('comments').add(newComment);
  };

  now = () => Timestamp.now();
}

const fsOrm = new FirestoreService();

export default fsOrm;
