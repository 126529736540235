import screens from '@constants/theme/screens.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Space } from 'antd';

export const SadaKCardList = styled(Space)`
  ${screens.mobile} {
    margin-bottom: ${spacings.xl};
  }
`;
