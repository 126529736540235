import { SadaUserCard } from './styles';
import firebase from 'firebase';
import { Avatar, Skeleton, Space } from 'antd';
import Typography from '@components/typography';
import useOrg from '@hooks/org.hook';

type Props = {
  user: firebase.User,
};

const UserCard = ({ user }: Props) => {
  const [org] = useOrg();
  return (
    <SadaUserCard>
      {user ? (
        <Space size="large">
          <Avatar size={72} src={user.photoURL} />
          <Space direction="vertical">
            <Typography lg> Welcome back, {user.displayName}</Typography>
            {org && (
              <Typography sm>Thanks for connecting with {org?.name}</Typography>
            )}
          </Space>
        </Space>
      ) : (
        <Skeleton
          avatar={{ size: 72 }}
          paragraph={{ rows: 2 }}
          title={false}
          loading
          active
        />
      )}
    </SadaUserCard>
  );
};

export default UserCard;
