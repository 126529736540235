import { SadaGoogleLoginButton } from './styles';
import { ReactComponent as GoogleIcon } from './icon.svg';

const GoogleLoginButton = (props) => (
  <SadaGoogleLoginButton
    outline
    secondary
    icon={<GoogleIcon />}
    block
    {...props}
  >
    Sign in with Google
  </SadaGoogleLoginButton>
);

export default GoogleLoginButton;
