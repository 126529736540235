import firebase from 'firebase';
import { message } from 'antd';

class FunctionsService {
  constructor() {
    this.api = firebase.functions();
  }

  call = async (name, body, success) => {
    try {
      const functions = firebase.functions();
      if (process.env.NODE_ENV === 'development') {
        // functions.useEmulator('localhost', 5001);
      }
      const service = functions.httpsCallable(name);
      const { data } = await service(body);
      if (success) {
        message.success(success);
      }
      return data;
    } catch (error) {
      message.error(error.message || 'Something went wrong');
    }
  };

  sendInvite = async (payload, message) => {
    await this.call('invite', payload, message);
  };

  sendInviteApplicant = async (payload, message) => {
    await this.call('inviteApplicant', payload, message);
  };

  createCalendar = async (summary, message) =>
    this.call(
      'createCalendar',
      { summary, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      message,
    );

  getAvailability = async (date: Date, serviceId) => {
    return this.call('availability', {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      serviceId,
    });
  };

  createEvent = async (data) => this.call('createEvent', data);

  editEvent = async (data) => this.call('editEvent', data);

  getSignedUrl = () => this.call('signedUrl');
  getMediaLink = (id) => this.call('mediaLink', { id });
}

export default new FunctionsService();
