import KCardList from '../k-card-list';
import { type IOrganization } from '@types/organization.type';
import type ICase from '@types/case.type';
import * as R from 'ramda';
import KRef from '@components/k-ref';
import fsOrm from '@services/firestore.service';
import React from 'react';
import firebase from 'firebase';
import { getCaseContentString } from '@utils/case.utils';
import { ReactComponent as EmptyCases } from '@/assets/images/empty-cases.svg';
import StatusTag from '@components/status-tag';
import useList from '@hooks/list.hook';

type Props = {
  cases: [ICase],
  organization: IOrganization,
  parent: firebase.firestore.DocumentReference,
  loading: Boolean,
  emptyText: String,
};

const CaseContent = ({ id, parent }) => {
  const [appts, loading] = useList('appointments', {
    where: ['case', '==', fsOrm.cases(id).ref()],
  });

  const [appt] = appts || [];

  return loading ? '...' : appt ? getCaseContentString(appt) : '';
};

const CaseList = ({
  cases,
  organization,
  parent,
  loading,
  onClick,
  emptyText = 'There are no upcoming cases',
}: Props) => {
  return (
    <KCardList
      data={cases}
      loading={loading}
      config={{
        name: R.always(organization?.name),
        photo: R.always(organization?.splash),
        title: R.always(organization?.name),
        subTitle: ({ service }) => (
          <KRef dataRef={service}>
            {(value, loading) => {
              return loading ? '...' : value?.title || null;
            }}
          </KRef>
        ),
        content: ({ id }) => <CaseContent id={id} />,
        action: ({ status }) => <StatusTag flat md {...status} />,
      }}
      onClick={onClick}
      emptyImage={<EmptyCases />}
      emptyText={emptyText}
    />
  );
};

export default CaseList;
