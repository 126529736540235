import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Menu } from 'antd';

export const SadaAppointmentMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${spacings.xs};
    }
  }
`;
