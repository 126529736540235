import { buttons } from '@constants/theme/buttons.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { transitions } from '@constants/theme/transitions.config';
import { colors } from '@constants/theme/colors.config';

const activeState = css`
  background: ${colors.blue};
  svg {
    g {
      fill: #fff;
    }
  }
  border-color: ${colors.blue};
  span {
    color: ${colors.almost_white};
  }
`;

const inactiveState = css`
  background: ${colors.almost_white};
  border-color: ${colors.almost_white};
  svg {
    opacity: 0.5;
  }
  span {
    color: ${colors.dark_gray};
  }
  &:hover {
    span {
      color: ${colors.blue};
    }
    svg {
      path {
        fill: ${colors.blue};
      }
    }
  }
`;

const isActive = ({ active }) => (active ? activeState : inactiveState);

export const SadaMenuButton = styled.button`
  border: 0;
  padding: 0;
  display: flex;
  height: ${buttons.md};
  ${isActive}
  border-radius: ${radiuses.lg};
  width: 100%;
  align-items: center;
  transition: all ${transitions.md};
  svg {
    margin: 0 ${spacings.sm};
    transition: fill ${transitions.md};
  }
  span {
    transition: color ${transitions.md};
  }
  cursor: pointer;
  &:focus {
    outline: none;
    border-color: ${colors.blue};
  }
  border-width: 2px;
  border-style: solid;
  padding-right: ${spacings.sm};
`;
