import { FormListFieldData, Form } from 'antd';
import { v4 } from 'uuid';
import { useEffect, useState } from 'react';
import { ListItemIn } from '@components/ui/animations';
import KInput from '@components/k-input';
import FieldListButtons from '@components/field-list-buttons';

type Props = { listField: FormListFieldData };

const KFormListItem = ({ listField, add, move, remove, length }: Props) => {
  const idx = listField.name;
  const [key, setKey] = useState();
  useEffect(() => {
    setKey(v4());
  }, []);
  return (
    <ListItemIn key={key}>
      <Form.Item
        labelCol={0}
        wrapperCol={24}
        rules={[{ required: true }]}
        {...listField}
      >
        <KInput
          autoFocus
          suffix={
            <FieldListButtons
              onDelete={() => {
                remove(idx);
              }}
              onDown={() => {
                move(idx, idx + 1);
              }}
              onUp={() => {
                move(idx, idx - 1);
              }}
              last={idx === length - 1}
              first={idx === 0}
              sm
            />
          }
        />
      </Form.Item>
    </ListItemIn>
  );
};

export default KFormListItem;
