import { SadaKIconButton } from './styles';
import Wave from 'antd/es/_util/wave';
import { type Element } from 'react';
import { type FC } from 'react';

type Props = {
  icon: Element,
  dark: boolean,
  danger: boolean,
  sm: boolean,
  disabled: boolean,
  bg: boolean,
};

const KIconButton: FC<Props> = ({ icon, dark, ...props }) => (
  <Wave>
    <SadaKIconButton role="button" dark={dark} {...props}>
      {icon}
    </SadaKIconButton>
  </Wave>
);

export default KIconButton;
