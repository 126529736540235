import { FadeZoomIn } from '@components/ui/animations';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import circles from './circles.svg';

export const SadaMeetCard = styled(FadeZoomIn)`
  border-radius: ${radiuses.lg};
  background: ${({ code }) => (code ? colors.blue : colors.almost_black)};
  color: #fff;
  background-image: url('${circles}');
  background-position: top right;
  background-position: contain;
  background-repeat: no-repeat;
  padding: ${spacings.xl} ${spacings.xxl};
`;
