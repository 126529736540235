const { useStore } = require('react-context-hook');

const useApplyForm = () => {
  const [form, setForm] = useStore('apply-form', {});
  const setAnswers = async (answers, step) => {
    await setForm({
      ...form,
      answers: { ...form?.answers, ...answers },
      [step]: true,
    });
  };
  const setFiles = async (files) => {
    await setForm({ ...form, files });
  };
  const setSchedule = async (schedule) => {
    await setForm({ ...form, schedule });
  };
  const finishStep = async (stepId) => {
    await setForm({ ...form, [stepId]: true });
  };
  return { data: form, setFiles, setSchedule, setAnswers, finishStep };
};

export default useApplyForm;
