import { SadaAppointmentCard } from './styles';
import type IAppointment from '@types/appointment.type';
import { humanTimestamp, humanTimestampTime } from '@utils/date.utils';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import useAuth from '@hooks/auth.hook';
import fsOrm from '@services/firestore.service';
import AppointmentMenu from '@components/appointment-menu';
import useModal from '@hooks/modal.hook';
import functionsService from '@services/functions.service';
import StatusTag from '@components/status-tag';
import { getApptTimeStatus } from '@utils/appointment.utils';
import AppointmentTimeTag from '@components/appointment-time-tag';

type Props = IAppointment & {};

const AppointmentCard = (props: Props) => {
  const {
    id,
    type,
    created,
    employee,
    case: caseRef,
    applicant,
    start,
    end,
    service: serviceRef,
    comments,
    eventId,
    status,
  } = props;
  const [caseManager, loading] = useDocumentData(employee);
  const [service] = useDocumentData(serviceRef);
  const [user] = useAuth();
  const { open } = useModal('edit-appointment');
  const timeStatus = getApptTimeStatus({ start, end });
  const onAction = async ({ key }) => {
    if (key === 'edit') {
      open({ ...props, service });
    } else {
      try {
        await fsOrm.appointments(id).update({ response: key });
        await fsOrm.setStatus(key, fsOrm.appointments(id).ref());
        await fsOrm.activity().create({
          appointment: fsOrm.appointments(id).ref(),
          employee,
          applicant,
          case: caseRef,
          action: key,
        });
        await functionsService.editEvent({
          eventId,
          calendarId: service.calendarId,
          status: { email: user.email, response: key },
        });
      } catch (error) {}
    }
  };
  return (
    <SadaAppointmentCard
      title={type}
      extra={<AppointmentMenu user={user} employee={employee} onAction={onAction} />}
      tag={timeStatus && <AppointmentTimeTag status={timeStatus} />}
      data={[
        [
          { label: 'Date Created', value: humanTimestamp(created) },
          { label: 'Meeting Date', value: humanTimestamp(start) },
          {
            label: 'Meeting Time',
            value: `${humanTimestampTime(start)} - ${humanTimestampTime(end)}`,
          },
        ],
        [
          { label: 'Specialist', value: caseManager?.name, loading },
          {
            label: 'Status',
            value: <StatusTag {...status} />,
          },
        ],
      ]}
    >
      {comments}
    </SadaAppointmentCard>
  );
};

export default AppointmentCard;
