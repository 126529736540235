import styled from '@emotion/styled';
import { UIBox, UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import { sizes } from '@constants/theme/sizes.config';
import screens from '@constants/theme/screens.config';

export const SadaSiderBackdrop = styled.div`
  position: absolute;
  left: -100%;
  right: 100%;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  display: none;
`;

export const SadaLayout = styled(UIBox)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  ${screens.mobile} {
    ${SadaSiderBackdrop.toString()} {
      display: block;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      ${({ menu }) =>
        menu
          ? `
        left: 0;
        right: 0;
        opacity: 1;
      `
          : ''}
    }
  }
`;

export const SadaSider = styled(UIBox)`
  width: 240px;
  height: 100%;
  flex-shrink: 0;
  height: 100%;
  ${screens.mobile} {
    position: absolute;
    z-index: 100;
    transition: all 0.2s ease-in-out;
    left: ${({ menu }) => (menu ? `0` : `-100%`)};
  }
`;

export const SadaContent = styled(UIFlexBox)`
  flex-grow: 1;
  height: 100%;
  background: ${colors.white_1};
  > * {
    max-width: ${sizes.width};
  }
  flex-direction: column;
`;

export const SadaScroll = styled(UIBox)`
  height: 100%;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  > * {
    padding: 0 ${spacings.xxxl} ${spacings.xxxl};
    ${screens.mobile} {
      padding: 0 ${spacings.lg} ${spacings.lg};
    }
  }
`;
