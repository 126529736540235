import useAuth from '@hooks/auth.hook';
import { Dropdown, Menu, Space } from 'antd';
import { Edit, Logout } from 'react-iconly';
import { SadaUserMenu, SadaUserMenuDropdown } from './styles';
import { logout } from '@utils/firebase.utils';
import KAvatar from '@components/k-avatar';

const UserMenu = ({ onEdit = () => {} }) => {
  const [user] = useAuth();
  const onMenuClick = ({ key }) => {
    if (key === 'logout') {
      logout();
    } else if (key === 'edit') {
      onEdit();
    }
  };
  return (
    <SadaUserMenu size="middle">
      {/* <KIconButton icon={<Notification />} dark /> */}
      <Space>
        <Dropdown
          trigger="click"
          overlay={
            <SadaUserMenuDropdown onClick={onMenuClick}>
              <Menu.Item key="edit" icon={<Edit />}>
                Edit profile
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="logout" icon={<Logout />}>
                Sign out
              </Menu.Item>
            </SadaUserMenuDropdown>
          }
        >
          <KAvatar photo={user?.photoURL} name={user?.displayName} />
        </Dropdown>
      </Space>
    </SadaUserMenu>
  );
};

export default UserMenu;
