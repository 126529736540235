import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaLoginForm = styled.div`
  .ant-form {
    height: auto;
    .ant-form-item-with-help {
      margin-bottom: ${spacings.xl};
    }
  }
`;
