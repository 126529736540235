import { useCollectionData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase';

const useList: T = (collection, { where } = {}): [[T], boolean, Error] => {
  let col = firebase.firestore().collection(collection);
  if (where) {
    col = col.where(...where);
  }

  const [value, loading, error] = useCollectionData(col);
  return [value, loading, error];
};

export default useList;
