import { SadaOrgCard } from '@components/cards/org-card/styles';
import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.almost_white};
  justify-content: space-between;
  height: 100vh;
  ${SadaOrgCard.toString()} {
    margin: ${spacings.xxl};
  }
`;
