import StatusTag from '@components/status-tag';
import Typography from '@components/typography';
import { SadaKInfo } from './styles';
import type IInfo from '@types/info.type';

type Props = IInfo & {
  label: String,
  value: String,
  type: String,
  status: String,
  loading: Boolean,
};

const KInfo = ({ label, value, type, status, loading }: Props) => (
  <SadaKInfo>
    <Typography>{label}</Typography>
    {type === 'tag' ? (
      <StatusTag {...{ [status]: true }} text={value} />
    ) : (
      <Typography sub loading={loading}>
        {value}
      </Typography>
    )}
  </SadaKInfo>
);

export default KInfo;
