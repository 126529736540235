import Typography from '@components/typography';
import { UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import functionsService from '@services/functions.service';
import { uploadFile } from '@utils/upload.utils';
import { message, Space, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { PaperUpload } from 'react-iconly';
import { SadaApplicationQuestionsFormUpload } from './styles';
import useApplyForm from '@hooks/apply-form.hook';
import firebase from 'firebase';
import type IFormStep from '@types/form-step.type';

const { Dragger } = Upload;

type Props = { formId: String, files: Array, stepId: String, step: IFormStep };

const ApplicationQuestionsFormUpload = ({
  formId,
  files,
  stepId,
  step,
}: Props) => {
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    const getUrl = async () => {
      setLoading(true);
      setUrl(await functionsService.getSignedUrl());
      setLoading(false);
    };
    getUrl();
  }, []);

  const { data, setFiles } = useApplyForm();
  const fileList =
    data?.files?.map(({ filename, uri }) => ({
      name: filename,
      url: uri,
      response: { url: uri },
      status: 'done',
    })) || [];
  const onChange = async ({ fileList, event }) => {
    if (!event) {
      const attachments = fileList?.map(({ response, name, url }) => ({
        uri: response?.url,
        filename: name,
        timestamp: firebase.firestore.Timestamp.now(),
      }));
      await setFiles(attachments);
    }
  };
  const props = {
    defaultFileList: fileList,
    name: 'file',
    action: url,
    accept: 'image/*,.pdf,.doc,.docx',
    listType: 'picture',
    method: 'put',
    customRequest: uploadFile,
    onPreview: () => {},
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    disabled: !url,
  };

  return (
    <SadaApplicationQuestionsFormUpload spinning={loading}>
      <Dragger {...props} onChange={onChange}>
        <UIFlexBox flexDirection="column" px={2} py={4} alignItems="center">
          <Space direction="vertical" size={32}>
            <PaperUpload set="bulk" primaryColor={colors.blue} size={96} />
            <Space direction="vertical" size={4}>
              <Typography>{step.description}</Typography>
              <Typography sub>
                File Types Accepted：.doc .docx .pdf .jpg .png .jpeg .gif
              </Typography>
            </Space>
          </Space>
        </UIFlexBox>
      </Dragger>
    </SadaApplicationQuestionsFormUpload>
  );
};

export default ApplicationQuestionsFormUpload;
