import { SadaApplicantQuestionsFormNavStepper } from './styles';
import type IFormStep from '@types/form-step.type';
import { Timeline } from 'antd';
import { useStore } from 'react-context-hook';
import { useEffect } from 'react';
import ApplicantQuestionsFormNavStepperItem from './applicant-questions-form-nav-stepper-item';
import { lastStep } from '../../config';
import useApplyForm from '@hooks/apply-form.hook';

type Props = { steps: [IFormStep], onStepClick: Function };

const ApplicantQuestionsFormNavStepper = ({ steps, onStepClick }: Props) => {
  const [currentStep, setStep] = useStore('step');
  useEffect(() => {
    setStep(steps[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useApplyForm();

  return (
    <SadaApplicantQuestionsFormNavStepper>
      <Timeline>
        {[...steps, lastStep].map((step, index) => (
          <ApplicantQuestionsFormNavStepperItem
            key={step?.id}
            active={currentStep === step?.id}
            last={index === steps.length}
            index={index}
            finished={data?.[step?.id]}
            onClick={() => {
              setStep(step?.id);
              onStepClick();
            }}
            {...step}
          />
        ))}
      </Timeline>
    </SadaApplicantQuestionsFormNavStepper>
  );
};

export default ApplicantQuestionsFormNavStepper;
