import { UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaOrgCard = styled.div`
  border: 1px solid ${colors.light_gray};
  border-radius: ${radiuses.md};
  overflow: hidden;
  background: ${colors.white};
`;

export const SadaOrgCardCover = styled.div`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  height: 80px;
  background-color: ${colors.light_gray};
`;

export const SadaOrgCardContent = styled(UIFlexBox)`
  padding: ${spacings.sm};
  .ant-space {
    width: 100%;
  }
`;
