import { SadaTypography } from '@components/typography/styles';
import { UIFlexBox } from '@components/ui/layout';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaKInfo = styled(UIFlexBox)`
  justify-content: space-between;
  > ${SadaTypography.toString()} {
    &:first-of-type {
      margin-right: ${spacings.sm};
    }
  }
`;
