import { SadaKTimeButton } from './styles';
import { ButtonProps } from 'antd';

type Props = ButtonProps & { highlight: Boolean, active: Boolean };

const KTimeButton = ({ highlight, active, ...props }: Props) => (
  <SadaKTimeButton {...props} block type={active ? 'primary' : 'default'} />
);

export default KTimeButton;
