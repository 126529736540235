import { FadeZoomIn } from '@components/ui/animations';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import circles from './circles.svg';

export const SadaUserCard = styled(FadeZoomIn)`
  padding: ${spacings.xxl};
  background-color: ${colors.almost_black};
  background-image: url(${circles});
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -83px;
  border-radius: ${radiuses.lg};
  color: ${colors.white};
  .ant-skeleton-content {
    vertical-align: middle;
  }
  .ant-skeleton-paragraph {
    margin-bottom: 0;
    & > li + li {
      margin-top: ${spacings.sm};
    }
  }
`;
