import { SadaKFormList } from './styles';
import { FormItemProps, Form, FormInstance } from 'antd';
import KHeading from '@components/k-heading';
import { Plus } from 'react-iconly';
import Typography from '@components/typography';
import KFormListItem from './k-form-list-item';

type Props = { field: FormItemProps, form: FormInstance };

const KFormList = ({ field, form }: Props) => (
  <SadaKFormList>
    <Form.List {...field} key={field.name}>
      {(listFields, { add, remove, move }, { errors }) => (
        <>
          <KHeading
            md
            title={field.label}
            icon={<Plus />}
            onAction={() => {
              add(`${field.listLabel} ${listFields.length + 1}`);
            }}
          />
          {listFields.map((field) => (
            <KFormListItem
              listField={field}
              {...{ add, remove, move }}
              length={listFields.length}
            />
          ))}

          {!!errors.length && <Typography danger> {errors}</Typography>}
        </>
      )}
    </Form.List>
  </SadaKFormList>
);

export default KFormList;
