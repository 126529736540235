import { SlideIn } from '@components/ui/animations';
import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Form } from 'antd';

export const SadaApplicantQuestionsFormStep = styled.div`
  background: ${colors.white};
  min-height: 100%;
  flex-grow: 1;
`;

export const StepForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StepFormQuestion = styled(SlideIn)`
  min-height: 100%;
  padding: 100px;
  background: ${colors.white};
  @media screen and (max-width: 600px) {
    padding: ${spacings.xl};
  }
`;

export const StepFormContainer = styled(Form)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${spacings.xxl};
  background: ${colors.almost_white};
  @media screen and (max-width: 600px) {
    padding: ${spacings.md};
  }
  height: 100%;
  .ant-form {
    height: 100%;
  }
`;

export const StepContainer = styled.div``;
