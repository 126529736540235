import { SadaMenuButton } from './styles';
import { type Node } from 'react';
import { colors } from '@constants/theme/colors.config';

type Props = { icon: Node, label: String, active: boolean, divider: boolean };

const MenuButton = ({ icon: Icon, label, active, ...props }: Props) => (
  <SadaMenuButton active={active} {...props}>
    {Icon && (
      <Icon
        set="bulk"
        primaryColor={active ? colors.white : colors.almost_black}
      />
    )}
    <span>{label}</span>
    
  </SadaMenuButton>
);

export default MenuButton;
