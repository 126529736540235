import { useDocumentData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase';

const useData = (
  collection,
  id,
  converter: firebase.firestore.FirestoreDataConverter,
): [T, boolean, Error] => {
  let col = firebase.firestore().collection(collection);
  if (converter) {
    col = col.withConverter(converter);
  }
  const [value, loading, error] = useDocumentData(col.doc(id || 'null'));
  return [value, loading, error];
};

export default useData;
