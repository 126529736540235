import KBtn from '@components/k-btn';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaApplicantQuestionsFormNav = styled.div`
  padding: ${spacings.xxxl};
  width: 100%;
  max-width: 450px;
  .ant-skeleton-avatar-square {
    border-radius: ${radiuses.md};
  }
  & > .ant-space {
    padding: 0 ${spacings.md};
  }
  ${KBtn.toString()} {
    display: none;
  }
  @media screen and (max-width: 600px) {
    height: 100vh;
    padding: ${spacings.sm};
    width: 100%;
    max-width: auto;
    ${KBtn.toString()} {
      display: block;
    }
  }
`;
