import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaDocumentsCardRow = styled.div`
  padding: ${spacings.md} ${spacings.lg};
  border-bottom: 1px solid ${colors.light_gray};
  display: flex;
  align-items: center;
  position: relative;

  .ant-image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 24px;
    opacity: 0;
  }
  &:last-of-type {
    border-bottom: none;
  }
  svg {
    margin-right: ${spacings.md};
    flex-shrink: 0;
  }
  .ant-space {
    flex-grow: 1;
  }
`;
