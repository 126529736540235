import { SadaKModal } from './styles';
import { ModalProps, Space } from 'antd';
import KBtn from '@components/k-btn';

type Props = ModalProps & { flat: Boolean };

const KModal = ({ ...props }: Props) => (
  <SadaKModal
    maskClosable={false}
    keyboard={!props.okButtonProps?.loading}
    getContainer
    footer={
      <Space>
        <KBtn
          secondary
          sub
          onClick={props.onCancel}
          {...props.cancelButtonProps}
        >
          {props.cancelText || 'Cancel'}
        </KBtn>
        <KBtn onClick={props.onOk} {...props.okButtonProps}>
          {props.okText || 'Submit'}
        </KBtn>
      </Space>
    }
    {...props}
  ></SadaKModal>
);

export default KModal;
