import { SadaCase } from './styles';
import KBreadcrumbs from '@components/k-breadcrumbs';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Col, Empty, Row, Space } from 'antd';
import KHeading from '@components/k-heading';
import CaseDetailsCard from '@components/cards/case-details-card';
import AppointmentCard from '@components/cards/appointment-card';
import ApplicantCard from '@components/cards/applicant-card';
import EmployeeCard from '@components/cards/employee-card';
import useAuth from '@hooks/auth.hook';
import fsOrm from '@services/firestore.service';
import useOrg from '@hooks/org.hook';
import DocumentsSection from '@components/documents-section';
import useData from '@hooks/data.hook';
import firebase from 'firebase';
import StatusTag from '@components/status-tag';
import * as R from 'ramda';
import useModal from '@hooks/modal.hook';
import KIconButton from '@components/k-icon-button';
import { Paper } from 'react-iconly';
import ActivityList from '@components/activity-list';
import MeetCardWrapper from '@components/meet-card-wrapper';
import React from 'react';
import ShowMobile from '@components/show-mobile';
import HideMobile from '@components/hide-mobile';

type Props = {
  id: String,
};

const Case = ({ id }: Props) => {
  const [auth] = useAuth();
  const applicantRef = fsOrm.applicants(auth.email).ref();
  const [org] = useOrg();
  const [applicant, applicantLoading] = useDocumentData(applicantRef);
  const [data, caseLoading] = useData('cases', id);
  const [service, serviceLoading] = useDocumentData(data?.service);
  const [appointments = []] = useCollectionData(
    firebase
      .firestore()
      .collection('appointments')
      .where('applicant', '==', fsOrm.applicants(auth.email).ref())
      .where('case', '==', fsOrm.cases(id).ref()),
  );
  const sortedAppts = R.pipe(
    R.sortBy(R.prop('start')),
    R.reverse,
  )(appointments);
  const [employee, employeeLoading] = useDocumentData(data?.employee);
  const onDocsChange = async (attachments) => {
    await fsOrm.cases(id).update({ attachments });
  };
  const { open } = useModal('answers');
  const topLeft = (
    <>
      <div>
        <KHeading title="Case Status" />
        <StatusTag {...data?.status} large />
      </div>
      <MeetCardWrapper
        caseId={id}
        emptyText="You have no upcoming appointments"
      />
    </>
  );
  return (
    <SadaCase>
      <KBreadcrumbs
        items={[
          { label: 'Case List', href: '/cases' },
          {
            label: `${service?.title} #${data?.id}`,
            loading: caseLoading || serviceLoading,
          },
        ]}
      />
      <Row gutter={40}>
        <Col sm={14}>
          <Space direction="vertical" size={24}>
            <ShowMobile>
              <Space size={40} direction="vertical">
                {topLeft}
              </Space>
            </ShowMobile>
            <div>
              <KHeading
                title="Case Details"
                extra={
                  data?.formAnswers && (
                    <KIconButton
                      icon={<Paper />}
                      onClick={() => {
                        open({ form: data?.formAnswers });
                      }}
                    />
                  )
                }
              />
              <CaseDetailsCard
                caseData={[data, caseLoading]}
                service={[service, serviceLoading]}
                cover={{ image: org?.splash, title: org?.name }}
              />
            </div>
            <div>
              <KHeading title="Appointments" />
              <Space direction="vertical">
                {sortedAppts?.length ? (
                  sortedAppts.map((appointment) => (
                    <AppointmentCard
                      key={appointment.id}
                      {...appointment}
                      form={data?.formAnswers}
                    />
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Space>
            </div>
            <div>
              <KHeading title="Applicant" />
              <ApplicantCard applicant={applicant} loading={applicantLoading} />
            </div>
            <div>
              <KHeading title="Employment Specialist" />
              <EmployeeCard employee={employee} loading={employeeLoading} />
            </div>
          </Space>
        </Col>
        {data && (
          <Col xs={24} sm={10}>
            <Space size={40} direction="vertical">
              <HideMobile>
                <Space size={40} direction="vertical">
                  {topLeft}
                </Space>
              </HideMobile>

              <DocumentsSection
                docs={data?.attachments}
                onChange={onDocsChange}
              />
              <ActivityList
                query={firebase
                  .firestore()
                  .collection('activity')
                  .where('case', '==', fsOrm.cases(id).ref())}
                view="applicant"
              />
            </Space>
          </Col>
        )}
      </Row>
    </SadaCase>
  );
};

export default Case;
