import MenuItem from './menu-item';
import { SadaMenu } from './styles';
import * as R from 'ramda';
import Logo from './logo';
import { type IMenuItem } from '@/types/menu.type';
import { type Node } from 'react';

type Props = {
  routes: [IMenuItem],
  footer: Node,
};
const Menu = ({ routes, footer }: Props) => (
  <SadaMenu>
    <div>
      <Logo />
      {R.pipe(
        R.values,
        R.reject(R.prop('hideMenu')),
        R.map((item) => (
          <MenuItem
            key={item.match || item.path}
            href={item.path}
            {...item}
            routes={routes}
          />
        )),
      )(routes)}
    </div>
    {footer}
  </SadaMenu>
);

export default Menu;
