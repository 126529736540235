import * as R from 'ramda';

export const getStylebooleanProp = (keys, config, defaultKey) =>
  R.pipe(
    R.pick(keys),
    R.filter(R.identity),
    R.keys,
    R.last,
    R.when(R.isNil, R.always(defaultKey)),
    R.prop(R.__, config),
  );
