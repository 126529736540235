import { spacings } from '@constants/theme/spacing.config';
import { fontSizes } from '@constants/theme/typography.config';
import styled from '@emotion/styled';

export const SadaApplicantQuestionsFormQuestion = styled.div`
  margin-bottom: ${spacings.md};
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    font-size: ${fontSizes.md};
    margin-bottom: ${spacings.sm};
  }
`;
