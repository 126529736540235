import Typography from '@components/typography';
import {
  SadaContentCard,
  SadaContentCardCover,
  SadaContentCardData,
  SadaContentCardHeader,
  SadaContentCardChildren,
} from './styles';
import { type Node, type FC } from 'react';
import KIconButton from '@components/k-icon-button';
import { Col, Space } from 'antd';
import KInfo from '@components/k-info';
import type IInfo from '@types/info.type';

type Props = {
  cover: {
    title: String,
    image: String,
  },
  title: String,
  icon: Node,
  extra: Node,
  tag: Node,
  data: [[IInfo]],
  light: boolean,
  onAction: Function,
  noPadding: Boolean,
};

const ContentCard: FC<Props> = ({
  cover,
  title,
  icon,
  children,
  data,
  light,
  onAction,
  extra,
  noPadding,
}) => (
  <SadaContentCard light={light}>
    {cover && (
      <SadaContentCardCover image={cover.image}>
        <Typography xl bold>
          {cover.title}
        </Typography>
      </SadaContentCardCover>
    )}
    {title && (
      <SadaContentCardHeader>
        <Typography md bold>
          {title}
        </Typography>
        {icon && <KIconButton icon={icon} onClick={onAction} />}
        {extra}
      </SadaContentCardHeader>
    )}
    {data && (
      <SadaContentCardData>
        {data.map((rows, idx) => (
          <Col xs={24} sm={24 / data.length} key={idx}>
            <Space direction="vertical">
              {rows.map((row) => (
                <KInfo {...row} key={row.label} />
              ))}
            </Space>
          </Col>
        ))}
      </SadaContentCardData>
    )}
    {children && (
      <SadaContentCardChildren noPadding={noPadding}>{children}</SadaContentCardChildren>
    )}
  </SadaContentCard>
);

export default ContentCard;
