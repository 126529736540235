import { SadaMenuItem } from './styles';
import { type IMenuItem } from '@/types/menu.type';
import useRoute from '@hooks/route.hook';
import MenuButton from '@components/menu-button';
import { navigate } from 'hookrouter';
import { Divider } from 'antd';
import { useStore } from 'react-context-hook';

type Props = IMenuItem & {
  href: String,
  routes: [IMenuItem],
};

const MenuItem = ({ href, label, icon, divider, hideMenu, routes }: Props) => {
  const route = useRoute(routes);
  const [, setMenu] = useStore('menu', false);

  return !hideMenu ? (
    <SadaMenuItem>
      {divider && <Divider />}
      <MenuButton
        label={label}
        active={href === route?.path}
        icon={icon}
        onClick={() => {
          navigate(href);
          setMenu(false);
        }}
      />
    </SadaMenuItem>
  ) : null;
};

export default MenuItem;
