import Login from '@components/login';
import { UIBox } from '@components/ui/layout';
import Layout from '@applicant/components/layout';
import useAuth from '@hooks/auth.hook';
import { withStore } from 'react-context-hook';
import Apply from './apply';
import { useRoutes } from 'hookrouter';
import React, { useEffect } from 'react';
import useOrg from '@hooks/org.hook';
import { useTitle } from 'hookrouter';
import useData from '@hooks/data.hook';
import fsOrm from '@services/firestore.service';
import { useQueryParams } from 'hookrouter';

const routes = {
  '/apply/:serviceId': ({ serviceId }) => <Apply service_id={serviceId} />,
};

const App = () => {
  const [user, loading] = useAuth();
  const routeResult = useRoutes(routes);
  const [applicant] = useData('applicants', user?.email || 'null');
  const status = applicant?.status?.status;
  useEffect(() => {
    if (status === 'pending') {
      fsOrm.setStatus('active', fsOrm.applicants(user?.email).ref());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const [q, setQueryParams] = useQueryParams();
  const email = user?.email;
  const applicantEmail = applicant?.email;
  const { name } = q;
  useEffect(() => {
    if (email && applicantEmail && name && applicantEmail === email) {
      setQueryParams({ name: '' });
      fsOrm.applicants(email).update({ name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, name, applicantEmail]);
  const [org] = useOrg();
  useTitle(`Portal${org?.name ? ` - ${org?.name}` : ''}`);
  return (
    <UIBox height="100%">
      {routeResult || (
        <>{!user ? <Login authLoading={loading} /> : <Layout />}</>
      )}
    </UIBox>
  );
};

export default withStore(App);
