import { SadaActivityList } from './styles';
import firebase from 'firebase';
import useFs from '@hooks/fs.hook';
import KCardList from '@components/lists/k-card-list';
import { ReactComponent as EmptyActivity } from '@/assets/images/empty-activity.svg';
import KHeading from '@components/k-heading';
import { CloseSquare, EditSquare, TickSquare } from 'react-iconly';
import { colors } from '@constants/theme/colors.config';
import KRef from '@components/k-ref';
import { humanTimestamp, humanTimestampTime } from '@utils/date.utils';
import Typography from '@components/typography';
import useAuth from '@hooks/auth.hook';

type Props = { query: firebase.firestore.Query };

const ActionText = ({ action, view, employee, applicant }) => {
  const [auth] = useAuth();
  switch (view) {
    case 'applicant':
      return (
        <KRef dataRef={employee}>
          {(user) => {
            return `Your appointment with ${user?.name} was ${action}.`;
          }}
        </KRef>
      );
    default:
      return (
        <KRef dataRef={applicant}>
          {(app) => (
            <KRef dataRef={employee}>
              {(user) => {
                return `${
                  user?.email === auth?.email ? 'You' : user?.name
                } ${action} appointment with ${app?.name}.`;
              }}
            </KRef>
          )}
        </KRef>
      );
  }
};
const icons = {
  accepted: <TickSquare primaryColor={colors.blue} set="bulk" size={48} />,
  declined: <CloseSquare primaryColor={colors.red} set="bulk" size={48} />,
  rescheduled: <EditSquare primaryColor={colors.orange} set="bulk" size={48} />,
};
const ActivityList = ({ query, view = 'employee' }: Props) => {
  const [activity = [], loading] = useFs({
    query: query.orderBy('created', 'desc').limit(6),
  });
  const data = activity?.map((a) => ({ ...a, id: a?.timestamp?.seconds }));
  return (
    <SadaActivityList>
      <KHeading title="Activity" />
      <KCardList
        data={data}
        loading={loading}
        config={{
          icon: ({ action }) => icons[action] || null,
          subTitle: (props) => <ActionText {...props} view={view} />,
          content: ({ created }) => (
            <Typography xs>
              {humanTimestamp(created)} {humanTimestampTime(created)},
            </Typography>
          ),
        }}
        emptyImage={<EmptyActivity />}
        emptyText="There's no recent activity."
        flatMobile
      />
    </SadaActivityList>
  );
};

export default ActivityList;
