import * as R from 'ramda';

export const emailPattern = /^\S+@\S+\.\S+$/;
export const phonePattern = /\(\d{3}\) \d{3}-\d{4}/;

export const minLengthValidator = (min, message) => (rule, value) => {
  return (value || [])?.length < min ? Promise.reject(new Error(message)) : Promise.resolve();
};

export const employeeValidator = (employees) => (rule, value) => {
  if (!employees) {
    return Promise.resolve();
  } else {
    return employees.includes(value?.id)
      ? Promise.resolve()
      : Promise.reject('Employee not available at selected time');
  }
};

export const parseFormAnswers = (values, step, offset) =>
  R.pipe(
    R.prop('questions'),
    R.addIndex(R.reduce)(
      (acc, { id, text }, idx) => ({
        ...acc,
        [id]: {
          q: text,
          a: R.when(R.is(Object), R.filter(R.identity))(values[id] || '-'),
          order: offset + idx,
        },
      }),
      {},
    ),
  )(step);

export const cleanFormAnswers = R.pipe(
  R.filter(R.identity),
  R.map(R.when(R.is(Object), R.filter(R.identity))),
);
export const prependAnswers = R.pipe(
  R.toPairs,
  R.map(R.over(R.lensIndex(0), R.concat('answers.'))),
  R.fromPairs,
);
export const getQuestionOffset = (stepIndex) =>
  R.pipe(R.take(stepIndex), R.map(R.prop('questions')), R.flatten, R.length);

export const parseAnswer = R.map(
  R.evolve({
    a: R.when(R.pipe(R.type, R.equals('Object')), R.pipe(R.keys, R.join(','))),
  }),
);

export const extractFormValues = R.pipe(R.values, R.sortBy(R.prop('order')), parseAnswer);
