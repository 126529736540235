import { UIFlexBox } from '@components/ui/layout';
import screens from '@constants/theme/screens.config';
import styled from '@emotion/styled';

export const SadaApplicantQuestionsForm = styled(UIFlexBox)`
  height: 100%;
  ${screens.mobile} {
    flex-direction: column;
  }
`;
