import { SadaApplicantQuestionsFormQuestion } from './styles';
import type IFormQuestion from '@types/form-question.type';
import { Checkbox, Radio, Empty, Space, Input } from 'antd';
import KInput from '@components/k-input';
import KSelect from '@components/k-select';
import { Form } from 'antd';
import Typography from '@components/typography';
import React from 'react';
import { MaskedInput } from 'antd-mask-input';

type Props = IFormQuestion;

const Item = (props) => (
  <Form.Item
    wrapperCol={{ xs: 24, sm: 12, md: 8 }}
    {...props}
    {...(props.required
      ? { rules: [{ required: true, message: 'Required' }] }
      : {})}
  />
);

const components = {
  single: ({ id, ...props }: IFormQuestion) => <Item {...props} name={id}>
    <KInput {...props} />
  </Item>,
  textarea: ({ id, ...props }: IFormQuestion) => <Item {...props} name={id}>
    <Input.TextArea {...props} />
  </Item>,
  phone: ({ id, ...props }: IFormQuestion) => <Item {...props} name={id}>
    <MaskedInput
      mask="(111) 111-1111"
      {...props}
    />
  </Item>,
  text: ({ options, id, ...props }: IFormQuestion) =>
    options?.map((option) => (
      <Item {...props} name={[id, option]} key={`${id}.${option}`}>
        <KInput placeholder={option} />
      </Item>
    )),
  select: ({ options, id, ...props }: IFormQuestion) => (
    <Item {...props} name={id}>
      <KSelect
        {...props}
        options={options.map((v) => ({ key: v, value: v }))}
      />
    </Item>
  ),
  multiselect: ({ options, id, ...props }: IFormQuestion) => (
    <Item {...props} name={id}>
      <KSelect
        mode="multiple"
        {...props}
        options={options.map((v) => ({ key: v, value: v }))}
      />
    </Item>
  ),
  checkbox: ({ options, id, ...props }: IFormQuestion) =>
    options.map((option) => (
      <Form.Item
        name={[id, option]}
        key={`${id}.${option}`}
        valuePropName="checked"
      >
        <Checkbox>{option}</Checkbox>
      </Form.Item>
    )),
  checkboxgroup: ({ options, id, ...props }: IFormQuestion) => (
    <Form.Item {...props} name={id}>
      <Checkbox.Group>
        {options.map((option) => (
          <div key={`${id}.${option}`}>
            <Checkbox value={option}>{option}</Checkbox>
          </div>
        ))}
      </Checkbox.Group>
    </Form.Item>
  ),

  radio: ({ options, id, ...props }: IFormQuestion) => (
    <Form.Item {...props} name={id}>
      <Radio.Group>
        {options.map((option) => (
          <div key={`${id}.${option}`}>
            <Radio value={option}>{option}</Radio>
          </div>
        ))}
      </Radio.Group>
    </Form.Item>
  ),
};

const ApplicantQuestionsFormQuestion = ({
  type,
  text,
  caption,
  ...props
}: Props) => {
  const Component = components[type] || Empty;
  return (
    <SadaApplicantQuestionsFormQuestion>
      <Space direction="vertical" size={24}>
        <Space direction="vertical" size={4}>
          {text && (
            <Typography lg bold>
              {text}
            </Typography>
          )}
          {caption && (
            <Typography sm sub>
              {caption}
            </Typography>
          )}
        </Space>
        <Component {...props} />
      </Space>
    </SadaApplicantQuestionsFormQuestion>
  );
};

export default ApplicantQuestionsFormQuestion;
