import {
  SadaContent,
  SadaLayout,
  SadaSider,
  SadaSiderBackdrop,
  SadaScroll,
} from './styles';
import { useRoutes } from 'hookrouter';
import NotFound from '@components/not-found';
import * as R from 'ramda';
import Menu from '@components/menu';
import Header from './header';
import applicantRoutesConfig from '../../constants/routes.config';
import UserProfileDialog from '@components/modals/user-profile-dialog';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SlideIn } from '@components/ui/animations';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ApplicatonAnswersModal from '@components/modals/applicaton-answers-modal';
import { useStore } from 'react-context-hook';

const router = R.map(({ component: Component }) => (props) => (
  <Component {...props} key={Component.name} />
))(applicantRoutesConfig);

const Layout = () => {
  const component = useRoutes(router);
  const [menu, setMenu] = useStore('menu', false);

  return (
    <SadaLayout menu={menu}>
      <SadaSiderBackdrop
        onClick={() => {
          setMenu(false);
        }}
      />
      <SadaSider menu={menu}>
        <Menu routes={applicantRoutesConfig} />
      </SadaSider>

      <SadaContent>
        <Header />
        {component ? (
          <SadaScroll>
            <PerfectScrollbar>
              <AnimatePresence exitBeforeEnter>
                <SlideIn key={component.key}>{component}</SlideIn>
              </AnimatePresence>
            </PerfectScrollbar>
          </SadaScroll>
        ) : (
          <NotFound />
        )}
      </SadaContent>
      <UserProfileDialog />
      <ApplicatonAnswersModal />
    </SadaLayout>
  );
};

export default Layout;
