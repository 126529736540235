/* eslint-disable no-unreachable */
import KBtn from '@components/k-btn';
import { useStore } from 'react-context-hook';
import { SadaApplicationQuestionsFormFooter } from './styles';
import * as R from 'ramda';
import type IFormStep from '@types/form-step.type';
import { useState } from 'react';
import { FormInstance } from 'antd';
import React from 'react';
import useAuth from '@hooks/auth.hook';
import fsOrm from '@services/firestore.service';
import { newId } from '@utils/firebase.utils';
import firebase from 'firebase';
import functionsService from '@services/functions.service';
import KModal from '@components/modals/k-modal';
import { navigate } from 'hookrouter';
import useApplyForm from '@hooks/apply-form.hook';

type Props = {
  steps: [IFormStep],
  form: FormInstance,
};

const ApplicationQuestionsFormFooter = ({
  steps = [],
  form,
  service,
}: Props) => {
  const [currentStep, setCurrentStep] = useStore('step');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const stepIndex = R.findIndex(R.propEq('id', currentStep))(steps);
  const isLast = stepIndex === steps.length - 1;
  const isFirst = stepIndex === 0;
  const { data, finishStep } = useApplyForm();

  const [user] = useAuth();
  const onSubmit = async () => {
    setLoading(true);
    const event = {
      service: service?.id,
      employee: data?.schedule?.employee,
      applicant: user.email,
      start: data?.schedule.start,
      end: data?.schedule.end,
      type: 'Initial meeting',
    };
    const caseId = await newId();
    const caseRef = fsOrm.cases(caseId).ref();
    await fsOrm.cases(caseId).create({
      employee: fsOrm.employees(data?.schedule?.employee).ref(),
      service: fsOrm.services(service?.id).ref(),
      applicant: fsOrm.applicants(user.email).ref(),
      created: firebase.firestore.Timestamp.now(),
      id: caseId,
      ...(data.answers ? { formAnswers: data.answers } : {}),
      ...(data.files ? { attachments: data.files } : {}),
    });
    const apptId = await newId();
    const calendarEvent = await functionsService.createEvent(event);
    const apptRef = fsOrm.appointments(apptId).ref();
    await fsOrm.appointments(apptId).create({
      eventId: calendarEvent?.id,
      meetUrl: calendarEvent.hangoutLink,
      type: 'Initial meeting',
      employee: fsOrm.employees(data?.schedule?.employee).ref(),
      applicant: fsOrm.applicants(user.email).ref(),
      case: caseRef,
      start: firebase.firestore.Timestamp.fromDate(
        new Date(data?.schedule.start),
      ),
      service: fsOrm.services(service?.id).ref(),
      end: firebase.firestore.Timestamp.fromDate(new Date(data?.schedule.end)),
      created: firebase.firestore.Timestamp.now(),
      id: apptId,
    });
    await fsOrm.setStatus('booked', apptRef, user.email);
    await fsOrm.setStatus('active', caseRef, user.email);
    setLoading(false);
    setModal(false);
    navigate(`/cases/${caseId}`);
  };

  const setNextStep = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      form.submit();
      setTimeout(async () => {
        setLoading(false);
        if (!isLast) {
          setCurrentStep(steps[stepIndex + 1]?.id);
        } else {
          await finishStep(currentStep);
          setCurrentStep('complete');
        }
      }, 300);
    } catch {}
  };
  const setPrevStep = async () => {
    form.submit();
    setTimeout(() => {
      setLoading(false);
      if (!isFirst) {
        setCurrentStep(steps[stepIndex - 1]?.id);
      }
    }, 300);
  };
  return (
    <SadaApplicationQuestionsFormFooter>
      {currentStep === 'complete' ? (
        <KBtn
          onClick={() => {
            setModal(true);
          }}
          disabled={!data?.schedule?.date || !data?.schedule?.start}
        >
          Submit
        </KBtn>
      ) : (
        <>
          <KBtn secondary onClick={setPrevStep} disabled={isFirst}>
            Previous Question
          </KBtn>
          <KBtn loading={loading} onClick={setNextStep}>
            {isLast ? 'Continue' : 'Next Question'}
          </KBtn>
        </>
      )}
      <KModal
        visible={modal}
        onCancel={() => {
          setModal(false);
        }}
        onOk={onSubmit}
        title="Are you sure you're ready to submit?"
        okButtonProps={{ loading }}
      >
        Please double check that your information is correct. After submitting,
        you won't be able to change your appointment
      </KModal>
    </SadaApplicationQuestionsFormFooter>
  );
};

export default ApplicationQuestionsFormFooter;
