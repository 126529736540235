import KIconButton from '@components/k-icon-button';
import { ArrowDown, ArrowUp, Delete, Edit } from 'react-iconly';
import { SadaFieldListButtons } from './styles';
import type FieldListButtonsProps from '@types/field-list-buttons-props.type';

type Props = FieldListButtonsProps;

const FieldListButtons = ({
  sm,
  first,
  last,
  onUp,
  onDown,
  onDelete,
  onEdit,
}: Props) => (
  <SadaFieldListButtons>
    {onUp && <KIconButton icon={<ArrowUp />} sm={sm} onClick={onUp} />}
    {onDown && <KIconButton icon={<ArrowDown />} sm={sm} onClick={onDown} />}
    {onDelete && (
      <KIconButton icon={<Delete />} sm={sm} danger onClick={onDelete} />
    )}
    {onEdit && <KIconButton icon={<Edit />} sm={sm} onClick={onEdit} />}
  </SadaFieldListButtons>
);

export default FieldListButtons;
