import KIconButton from '@components/k-icon-button';
import { useStore } from 'react-context-hook';
import { Category } from 'react-iconly';

const MobileMenuButton = () => {
  const [, setMenu] = useStore('menu', false);
  return (
    <KIconButton
      icon={<Category />}
      onClick={() => {
        setMenu(true);
      }}
    />
  );
};

export default MobileMenuButton;
