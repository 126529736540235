import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Breadcrumb } from 'antd';

export const SadaKBreadcrumbs = styled(Breadcrumb)`
  padding: ${spacings.sm} 0;
  border-top: 1px solid ${colors.light_gray};
  border-bottom: 1px solid ${colors.light_gray};
  .ant-breadcrumb-link {
    font-weight: 500;
  }
  & > span:last-child {
    .ant-breadcrumb-link {
      font-weight: 400;
    }
  }
  margin-bottom: ${spacings.xl};
`;
