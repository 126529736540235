import useAuth from '@hooks/auth.hook';
import { SadaApplicantFormSignup } from './styles';
import ApplicantFormAppointment from '../applicant-form-appointment';
import type IService from '@types/service.type';
import LoginForm from '@components/login/login-form';

type Props = { formId: String, service: IService };

const ApplicantFormSignup = ({ formId, service }: Props) => {
  const [auth] = useAuth();
  return (
    <SadaApplicantFormSignup>
      {auth ? (
        <ApplicantFormAppointment service={service} />
      ) : (
        <LoginForm view="signup" />
      )}
    </SadaApplicantFormSignup>
  );
};

export default ApplicantFormSignup;
