import { SadaApplicantQuestionsFormNavStepperItem } from './styles';
import type IFormStep from '@types/form-step.type';
import Typography from '@components/typography';
import { Avatar } from 'antd';
import { colors } from '@constants/theme/colors.config';
import { TickSquare } from 'react-iconly';
import { UIFlexBox } from '@components/ui/layout';

type Props = IFormStep & {
  finished: Boolean,
  active: Boolean,
  last: Boolean,
  onClick: Function,
  index: Number,
};

const ApplicantQuestionsFormNavStepperItem = ({
  id,
  finished,
  active,
  title,
  description,
  onClick,
  index,
  last,
}: Props) => {
  const Dot =
    finished && !active ? (
      <UIFlexBox
        justifyContent="center"
        alignItems="center"
        width={32}
        height={32}
        borderRadius={16}
        borderColor={colors.blue}
        borderWidth={1}
        borderStyle="solid"
      >
        <TickSquare
          set="two-tone"
          primaryColor={colors.white}
          secondaryColor={colors.blue}
          size="large"
        />
      </UIFlexBox>
    ) : (
      <Avatar
        size={32}
        style={
          active
            ? { background: colors.blue }
            : {
                background: colors.white,
                color: colors.dark_gray,
                borderWidth: 1,
                borderColor: colors.dark_gray,
                borderStyle: 'solid',
              }
        }
      >
        {index + 1}
      </Avatar>
    );
  return (
    <SadaApplicantQuestionsFormNavStepperItem
      dot={Dot}
      finished={finished ? 'finished' : 'unfinished'}
      last={last ? 'last' : 'not last'}
    >
      <Typography md onClick={onClick}>
        {title}
      </Typography>
      <Typography sub>{description}</Typography>
    </SadaApplicantQuestionsFormNavStepperItem>
  );
};

export default ApplicantQuestionsFormNavStepperItem;
