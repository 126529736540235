import { LoadingOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { navigate } from 'hookrouter';
import { SadaKBreadcrumbs } from './styles';

type Props = {
  items: [
    {
      href: String,
      label: String,
      loading: Boolean,
    },
  ],
};

const KBreadcrumbs = ({ items = [] }: Props) => {
  const onSelect = (item) => (e) => {
    e.preventDefault();
    navigate(item.href);
  };
  return (
    <SadaKBreadcrumbs>
      {items.map((item, idx) => (
        <Breadcrumb.Item
          href={item.href}
          key={item.href || idx}
          onClick={onSelect(item)}
        >
          {item.loading ? <LoadingOutlined /> : item.label}
        </Breadcrumb.Item>
      ))}
    </SadaKBreadcrumbs>
  );
};

export default KBreadcrumbs;
