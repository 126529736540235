import {
  SadaApplicantQuestionsFormStep,
  StepForm,
  StepFormContainer,
  StepFormQuestion,
} from './styles';
import type IFormStep from '@types/form-step.type';
import Typography from '@components/typography';
import ApplicantQuestionsFormQuestion from '../applicant-questions-form-question';
import { Form } from 'antd';
import ApplicationQuestionsFormUpload from '../application-questions-form-upload';
import ApplicationQuestionsFormFooter from '../application-questions-form-footer';
import { useStore } from 'react-context-hook';
import * as R from 'ramda';
import { AnimatePresence } from 'framer-motion';
import { getQuestionOffset, parseFormAnswers } from '@utils/form.utils';
import ApplicantFormSignup from '../applicant-form-signup';
import type IService from '@types/service.type';
import useApplyForm from '@hooks/apply-form.hook';
import { forwardRef } from 'react';

type Props = { steps: [IFormStep], service: IService };

const ApplicantQuestionsFormStep = forwardRef(
  ({ steps, action, service }: Props, ref) => {
    const [currentStep] = useStore('step');
    const stepIndex = R.findIndex(R.propEq('id', currentStep))(steps);
    const step = steps[stepIndex];
    const [form] = Form.useForm();
    const offset = getQuestionOffset(stepIndex)(steps);
    const { data, setAnswers } = useApplyForm();
    const onSubmit = async (values) => {
      if (step.type === 'list') {
        await setAnswers(parseFormAnswers(values, step, offset), step?.id);
      }
    };

    return (
      <SadaApplicantQuestionsFormStep ref={ref}>
        <StepForm>
          <StepFormContainer form={form} onFinish={onSubmit}>
            <AnimatePresence exitBeforeEnter>
              {currentStep === 'complete' ? (
                <StepFormQuestion>
                  <ApplicantFormSignup service={service} />
                </StepFormQuestion>
              ) : (
                step && (
                  <StepFormQuestion key={currentStep}>
                    {step.caption && <Typography lg>{step.caption}</Typography>}
                    {step.type === 'file' ? (
                      <ApplicationQuestionsFormUpload
                        files={data.files || []}
                        stepId={currentStep}
                        step={step}
                      />
                    ) : (
                      step.questions?.map((q, idx) => (
                        <ApplicantQuestionsFormQuestion
                          key={q?.id}
                          {...q}
                          order={offset + idx}
                        />
                      ))
                    )}
                  </StepFormQuestion>
                )
              )}
            </AnimatePresence>
          </StepFormContainer>
          <ApplicationQuestionsFormFooter
            steps={steps}
            form={form}
            service={service}
          />
        </StepForm>
      </SadaApplicantQuestionsFormStep>
    );
  },
);

export default ApplicantQuestionsFormStep;
