import { colors } from '@constants/theme/colors.config';
import {
  fontColors,
  fontSizes,
  fontWeights,
  lineSizes,
} from '@constants/theme/typography.config';
import styled from '@emotion/styled';
import { getStylebooleanProp } from '@utils/style.utils';
import { Skeleton } from 'antd';

export const SadaTypography = styled.div`
  font-size: ${getStylebooleanProp(['md', 'lg', 'xl', 'xxl'], fontSizes, 'sm')};
  line-height: ${getStylebooleanProp(
    ['md', 'lg', 'xl', 'xxl'],
    lineSizes,
    'sm',
  )};
  font-weight: ${getStylebooleanProp(
    ['medium', 'bold'],
    fontWeights,
    'regular',
  )};
  color: ${getStylebooleanProp(
    ['sub', 'danger'],
    fontColors,
    colors.almost_black,
  )};
  ${({ center }) => (center ? 'text-align: center;' : '')}
  ${({ onClick }) =>
    onClick ? 'cursor: pointer; &:hover {text-decoration: underline;}' : ''})
`;

export const SadaTypographySkeleton = styled(Skeleton)`
  .ant-skeleton-paragraph {
    margin-bottom: 0;
    li {
      height: ${getStylebooleanProp(
        ['md', 'lg', 'xl', 'xxl'],
        fontSizes,
        'sm',
      )};
      margin: calc(
          (
              ${getStylebooleanProp(['md', 'lg', 'xl', 'xxl'], lineSizes, 'sm')} -
                ${getStylebooleanProp(
                  ['md', 'lg', 'xl', 'xxl'],
                  fontSizes,
                  'sm',
                )}
            ) / 2
        )
        0 !important;
    }
  }
`;
