import DocumentsCard from '@components/cards/documents-card';
import KHeading from '@components/k-heading';
import { SadaDocumentsSection } from './styles';
import type IAttachment from '@types/attachment.type';
import { Plus } from 'react-iconly';
import { useUpload } from '@hooks/upload.hook';
import { useRef, useState } from 'react';
import KIconButton from '@components/k-icon-button';
import firebase from 'firebase';

type Props = { docs: [IAttachment], onChange: ([IAttachment]) => void };

const DocumentsSection = ({ docs = [], onChange }: Props) => {
  const [file, setFile] = useState();
  const onSuccess = (v) => {
    onChange([
      ...(docs ? docs : []),
      {
        uri: v.link,
        filename: file?.name,
        timestamp: firebase.firestore.Timestamp.now(),
      },
    ]);
    setFile(null);
  };
  const { progress, uploading } = useUpload({ file, onSuccess });
  const ref = useRef();
  const onSelect = async ({ target }) => {
    setFile(target.files?.[0]);
  };
  return (
    <SadaDocumentsSection>
      <input type="file" onChange={onSelect} ref={ref} />
      <KHeading
        title="Documents"
        extra={
          <KIconButton
            icon={<Plus />}
            onClick={() => {
              ref?.current?.click();
            }}
            disabled={uploading}
          />
        }
      />
      <DocumentsCard
        docs={docs}
        uploading={{ uploading, progress, filename: file?.name }}
        onChange={onChange}
      />
    </SadaDocumentsSection>
  );
};

export default DocumentsSection;
