import useModal from '@hooks/modal.hook';
import { SadaUserProfileDialog } from './styles';
import { Form, ModalProps } from 'antd';
import { useState } from 'react';
import KPhotoUpload from '@components/k-photo-upload';
import KFormModal from '../k-form-modal';
import { emailPattern, phonePattern } from '@utils/form.utils';
import { MaskedInput } from 'antd-mask-input';
import fsOrm from '@services/firestore.service';
import KInput from '@components/k-input';
import useAuth from '@hooks/auth.hook';

type Props = ModalProps & {};

const UserProfileDialog = ({ ...props }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { close, data } = useModal('profile');
  const { collection = 'applicants' } = data || {};
  const [user] = useAuth();
  const onSubmit = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    await fsOrm[collection](data.email).update(data);
    await user.updateProfile({ displayName: data.name, photoURL: data.photo });
    setLoading(false);
    close();
  };
  return (
    <SadaUserProfileDialog>
      <KFormModal
        name="profile"
        modalProps={{
          title: 'Edit Profile',
          onOk: () => {
            form.submit();
          },
          okButtonProps: { loading, disabled: loading },
        }}
        formProps={{
          form,
          onFinish: onSubmit,
          fields: [
            {
              name: 'photo',
              component: KPhotoUpload,
              labelCol: { xs: 0 },
              wrapperCol: { xs: 24 },
            },
            {
              label: 'Name',
              rules: [{ required: true, message: 'Name is required!' }],
              fieldKey: 'name',
              name: 'name',
            },
            {
              label: 'Email',
              rules: [
                { required: true, message: 'Email is required!' },
                {
                  pattern: emailPattern,
                  message: 'Invalid Email',
                },
              ],
              fieldKey: 'email',
              name: 'email',
              component: KInput,
              componentProps: {
                disabled: true,
              },
            },
            {
              group: true,
              label: 'Phone',
              fields: [
                {
                  rules: [
                    {
                      pattern: phonePattern,
                      message: 'Invalid Phone',
                    },
                  ],
                  fieldKey: 'phone',
                  name: 'phone',
                  component: MaskedInput,
                  componentProps: {
                    mask: '(111) 111-1111',
                  },
                },
                {
                  fieldKey: 'ext',
                  name: 'ext',
                  componentProps: {
                    placeholder: 'Ext.',
                  },
                },
              ],
            },
          ],
        }}
      />
    </SadaUserProfileDialog>
  );
};

export default UserProfileDialog;
