import { SadaKAvatar } from '@components/k-avatar/styles';
import { FadeZoomIn } from '@components/ui/animations';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import screens from '@constants/theme/screens.config';
import { shadows } from '@constants/theme/shadows.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaKListCard = styled(FadeZoomIn)`
  display: flex;
  align-items: center;
  background: ${colors.almost_white};
  padding: ${spacings.md} ${spacings.xl};
  ${SadaKAvatar.toString()} {
    flex-shrink: 0;
    margin-right: ${spacings.xl};
  }
  svg {
    flex-shrink: 0;
    margin-right: ${spacings.md};
  }
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: ${radiuses.md};
  &:hover {
    box-shadow: ${shadows.xs};
  }
  .ant-space {
    flex-grow: 1;
  }
  .ant-skeleton-with-avatar {
    width: auto;
  }
  ${screens.mobile} {
    ${({ flatMobile }) =>
      flatMobile
        ? ''
        : `
    flex-direction: column;
    ${SadaKAvatar.toString()} {
      display: none;
    }
    `}
  }
`;
