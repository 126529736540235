import firebase from 'firebase';
import { useMemo, useState } from 'react';
import * as R from 'ramda';
import { map } from 'rubico';

const populate = (keys) => async (row) => {
  let populated = row;
  await map(async (key) => {
    populated[key] = (await populated[key].get()).data();
  })(keys);
  return populated;
};

const fetch = async (data, keys = []) => {
  try {
    let rows = data.map((v) => ({ id: v.id, ...v.data() }));
    const v = await map(populate(keys))(rows);
    return v;
  } catch (error) {
    console.log(error);
  }
};

const useFs = ({
  query,
  doc,
  populate = [],
  disabled = false,
}): {
  query: firebase.firestore.Query,
  doc: firebase.firestore.Query,
  populate: [String],
} => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const listeners = [];
  const queryString = JSON.stringify(query);
  const docString = JSON.stringify(doc);
  useMemo(() => {
    try {
      if (query && !disabled) {
        listeners.push(
          query.onSnapshot(
            async (snapshot: firebase.firestore.QuerySnapshot) => {
              const rows = await fetch(snapshot.docs, populate);
              if (!R.equals(rows, data)) {
                setData(rows);
                setLoading(false);
              }
            },
          ),
        );
      }
      if (doc && !disabled) {
        listeners.push(
          query.onSnapshot((v: firebase.firestore.QueryDocumentSnapshot) => {
            const row = v.data();
            if (!R.equals(row, data)) {
              setData(row);
              setLoading(false);
            }
          }),
        );
      }
      return () => {
        listeners.forEach((l) => {
          l();
        });
      };
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, docString]);

  return [disabled ? null : data, loading];
};

export default useFs;
