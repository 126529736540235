import { SadaKHeading } from '@components/k-heading/styles';
import { SadaKIconButton } from '@components/k-icon-button/styles';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { sizes } from '@constants/theme/sizes.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import screens from '@constants/theme/screens.config';

export const SadaEmployeeCard = styled.div`
  border-radius: ${radiuses.md};
  background: ${colors.almost_white};
  ${SadaKHeading.toString()} {
    border-bottom: 1px solid ${colors.light_gray};
    padding-left: ${spacings.xl};
    padding-right: ${spacings.xl};
    height: ${sizes.mdd};
  }
  .ant-space {
    width: 100%;
    min-width: 340px;
    padding: ${spacings.xxxl} ${spacings.xxl};
    border-left: 1px solid ${colors.light_gray};
  }
  .ant-skeleton {
    width: auto;
    min-width: 70px;
  }
  ${screens.mobile} {
    padding-top: ${spacings.xl};
  }
`;

export const SadaEmployeeCardTitle = styled.div`
  padding: ${spacings.sm} ${spacings.xl};
  display: flex;
  ${SadaKIconButton.toString()} {
    margin-left: auto;
  }
  border-bottom: 1px solid ${colors.light_gray};
`;
