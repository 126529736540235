import KBtn from '@components/k-btn';
import Typography from '@components/typography';
import { Space } from 'antd';
import { ArrowRight } from 'react-iconly';
import { SadaMeetCard } from './styles';
import * as R from 'ramda';
import firebase from 'firebase';
import { humanTimestampDateTime } from '@utils/date.utils';

type Props = {
  code: String,
  start: firebase.firestore.Timestamp,
};

const MeetCard = ({
  code,
  start,
  emptyText = 'There are no upcoming meeings for this applicant',
}: Props) => (
  <SadaMeetCard code={code}>
    {code ? (
      <Space direction="vertical" size={12}>
        <Space direction="vertical">
          <Typography xxl bold>
            Next Google Meet
          </Typography>
          <div>
            <Typography md>Meeting code: {R.last(code?.split('/'))}</Typography>
            {start && (
              <Typography bold>{humanTimestampDateTime(start)}</Typography>
            )}
          </div>
        </Space>
        <KBtn
          icon={<ArrowRight />}
          iconright
          secondary
          onClick={() => {
            window.open(code, '_blank');
          }}
        >
          Start Meet
        </KBtn>
      </Space>
    ) : (
      <Space direction="vertical">
        <Typography xxl bold>
          Nothing planned.
        </Typography>
        <Typography sm>{emptyText}</Typography>
      </Space>
    )}
  </SadaMeetCard>
);

export default MeetCard;
