import { SadaEmployeeCard, SadaEmployeeCardTitle } from './styles';
import { Col, Row, Skeleton, Space } from 'antd';
import { UICenterBox, UIFlexBox } from '@components/ui/layout';
import Typography from '@components/typography';
import type IEmployee from '@types/employee.type';
import KAvatar from '@components/k-avatar';
import KIconButton from '@components/k-icon-button';
import { TwoUsers } from 'react-iconly';

type Props = {
  employee: IEmployee,
  loading: Boolean,
  onEdit: Function,
};

const EmployeeCard = ({ employee, loading, onEdit }: Props) => (
  <SadaEmployeeCard>
    {onEdit && (
      <SadaEmployeeCardTitle>
        <KIconButton icon={<TwoUsers />} onClick={onEdit} />
      </SadaEmployeeCardTitle>
    )}
    <Row>
      <Col xs={24} sm={7}>
        <UICenterBox px={4}>
          {loading ? (
            <Skeleton
              avatar={{ size: 72 }}
              paragraph={false}
              title={false}
              active
            />
          ) : (
            <KAvatar {...employee} size={72} />
          )}
        </UICenterBox>
      </Col>
      <Col xs={24} sm={17}>
        <Space direction="vertical">
          <UIFlexBox justifyContent="space-between">
            <Typography>Name</Typography>
            <Typography sub loading={loading}>
              {employee?.name}
            </Typography>
          </UIFlexBox>
          <UIFlexBox justifyContent="space-between">
            <Typography>Phone</Typography>
            <Typography sub loading={loading}>
              {employee?.phone} {employee?.ext ? `Ext: ${employee?.ext}` : ''}
            </Typography>
          </UIFlexBox>
          <UIFlexBox justifyContent="space-between">
            <Typography>Email</Typography>
            <Typography sub loading={loading}>
              {employee?.email}
            </Typography>
          </UIFlexBox>
        </Space>
      </Col>
    </Row>
  </SadaEmployeeCard>
);

export default EmployeeCard;
