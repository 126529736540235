import { UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaApplicationQuestionsFormFooter = styled(UIFlexBox)`
  justify-content: flex-end;
  align-items: center;
  background: ${colors.white};
  border-top: 1px solid ${colors.light_gray};
  height: 80px;
  flex-shrink: 0;
  padding: 0 ${spacings.xxl};
`;
