import { UICenterBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaKPhotoUploadHover = styled(UICenterBox)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  color: ${colors.white};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const SadaKPhotoUpload = styled.div`
  input {
    display: none;
  }
  margin: ${spacings.md} auto;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    ${SadaKPhotoUploadHover.toString()} {
      opacity: 1;
    }
  }
`;
