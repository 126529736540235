import KAvatar from '@components/k-avatar';
import { SadaKListCard } from './styles';
import { type Node } from 'react';
import Typography from '@components/typography';
import { Space } from 'antd';
import AppointmentTimeTag from '@components/appointment-time-tag';

export type KListCardProps = {
  name: Node,
  photo: string,
  title: Node,
  subTitle: Node,
  content: Node,
  action: Node,
  loading: Boolean,
  flatMobile: Boolean,
  icon: Node,
  status: string,
};

const KListCard = ({
  id,
  name,
  photo,
  title,
  subTitle,
  content,
  action,
  onClick = () => {},
  loading,
  icon,
  flatMobile,
  status,
}: Props) => (
  <SadaKListCard
    flatMobile={flatMobile}
    onClick={() => {
      onClick(id);
    }}
  >
    {icon ? icon : <KAvatar name={name} photo={photo} size={48} loading={loading} />}
    <Space direction="vertical" size={4}>
      <Space align="start">
        {title && (
          <Typography bold lg loading={loading}>
            {title}
          </Typography>
        )}
        {status && <AppointmentTimeTag status={status} />}
      </Space>
      <div>
        {subTitle && (
          <Typography md sub loading={loading}>
            {subTitle}
          </Typography>
        )}
        {content && (
          <Typography md sub loading={loading}>
            {content}
          </Typography>
        )}
      </div>
    </Space>
    {action}
  </SadaKListCard>
);

export default KListCard;
