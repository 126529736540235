import { SadaTypography, SadaTypographySkeleton } from './styles';
import { type Node } from 'react';
import * as R from 'ramda';

type Props = {
  sm: boolean,
  md: boolean,
  lg: boolean,
  xl: boolean,
  xxl: boolean,
  children: Node,
  medium: boolean,
  bold: boolean,
  sub: boolean,
  loading: boolean | Object,
  danger: boolean,
  center: boolean,
  onClick: Function,
};

const Typography = ({ children, loading, onClick, ...props }: Props) =>
  loading ? (
    <SadaTypographySkeleton
      title={false}
      paragraph={{ rows: 1, ...(R.type(loading) === 'Object' ? loading : {}) }}
      active
      {...props}
    />
  ) : (
    <SadaTypography {...R.map((v) => +v)(props)} onClick={onClick}>
      {children}
    </SadaTypography>
  );

export default Typography;
