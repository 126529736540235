import { SadaKHeading } from '@components/k-heading/styles';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import screens from '@constants/theme/screens.config';
import { sizes } from '@constants/theme/sizes.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaApplicantCard = styled.div`
  border-radius: ${radiuses.md};
  background: ${colors.almost_white};
  ${SadaKHeading.toString()} {
    border-bottom: 1px solid ${colors.light_gray};
    padding-left: ${spacings.xl};
    padding-right: ${spacings.xl};
    height: ${sizes.mdd};
  }
  .ant-space {
    width: 100%;
    padding: ${spacings.xxxl} ${spacings.xxl};
    border-left: 1px solid ${colors.light_gray};
  }
  .ant-skeleton {
    width: auto;
    min-width: 70px;
  }
  ${screens.mobile} {
    padding-top: ${spacings.xl};
  }
`;
