import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Button } from 'antd';

export const SadaBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radiuses.sm};
  padding: ${spacings.xs} ${spacings.lg} ${spacings.xs} ${spacings.md};
  flex-direction: ${({ iconright }) => (iconright ? 'row-reverse' : 'row')};
  ${({ outline, secondary }) =>
    outline && secondary
      ? `
    &, &:hover, &:active, &:focus {
      border-color: ${colors.blue};
    }
    `
      : ''}

  font-weight: bold;
  ${({ sub }) => (sub ? `color: ${colors.dark_gray};` : '')}
  > * {
    margin-left: 4px;
  }
  &.ant-btn-link {
    background: #fff;
  }
  &:disabled,
  &:disabled:hover {
    background: ${({ secondary }) =>
      !secondary ? colors.dark_gray : colors.white};
    color: ${({ secondary }) => (!secondary ? colors.white : colors.dark_gray)};
    border-color: ${({ secondary, outline }) =>
      !secondary ? colors.dark_gray : colors.white};
  }
`;
