import { spacings } from '@constants/theme/spacing.config';
import { fontSizes } from '@constants/theme/typography.config';
import styled from '@emotion/styled';
import { Modal } from 'antd';

export const SadaKModal = styled(Modal)`
  .ant-modal-close {
    top: 2px;
    .ant-modal-close-x {
      height: 48px;
    }
    /* right: ${spacings.xl}; */
  }
  .ant-modal-header {
    height: 48px;
    .ant-modal-title {
      font-size: ${fontSizes.lg};
    }
    padding: 0 ${spacings.xl};
    display: flex;
    align-items: center;
  }
  .ant-modal-body {
    padding: ${spacings.xl} ${({ flat }) => (flat ? spacings.xl : spacings.xxl)};
  }
  ${(p) => p?.okButtonProps?.loading ? '* { pointer-events: none; }' : ''};
`;
