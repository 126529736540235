import * as R from 'ramda';
import { isToday, isTomorrow } from 'date-fns';
import { isWithinInterval } from 'date-fns/fp';
import { invoke } from './ramda.utils';

const today = R.pipe(R.prop('start'), R.unless(R.isNil, R.pipe(invoke('toDate'), isToday)));
const tomorrow = R.pipe(R.prop('start'), R.unless(R.isNil, R.pipe(invoke('toDate'), isTomorrow)));
const now = R.pipe(
  R.pick(['start', 'end']),

  R.ifElse(
    R.pipe(R.values, R.all(R.identity)),
    R.pipe(R.map(invoke('toDate')), (v) => isWithinInterval(v, new Date())),
    R.F,
  ),
);

export const getApptTimeStatus = R.tryCatch(
  R.cond([
    [now, R.always('now')],
    [today, R.always('today')],
    [tomorrow, R.always('tomorrow')],
    [R.T, R.F]
  ]),
  R.F,
);
