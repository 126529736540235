import { SadaKHeading } from './styles';
import { type FC, type Node } from 'react';
import Typography from '../typography';
import KIconButton from '../k-icon-button';
import KBtn from '@components/k-btn';

type Props = {
  icon: Node,
  extra: Node,
  action: String,
  onAction: Function,
  title: String,
  xl: boolean,
  md: boolean,
};

const KHeading: FC<Props> = ({
  icon,
  action,
  onAction = () => {},
  xl,
  title,
  md,
  extra,
}) => (
  <SadaKHeading>
    <Typography lg={!xl && !md} xl={xl} md={md} bold>
      {title}
    </Typography>
    {extra && extra}
    {icon && <KIconButton icon={icon} onClick={onAction} />}
    {action && (
      <KBtn secondary onClick={onAction}>
        {action}
      </KBtn>
    )}
  </SadaKHeading>
);

export default KHeading;
