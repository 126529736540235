import { FC } from 'react';
import firebase from 'firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';

type Props = {
  dataRef: firebase.firestore.DocumentReference,
};

const KRef: FC<Props> = ({ dataRef, children }: Props) => {
  const [data, loading] = useDocumentData(dataRef);
  return children(data, loading);
};

export default KRef;
