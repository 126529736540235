import { SadaDocumentsCardRow } from './styles';
import firebase from 'firebase';
import { Image as ImageIcon, Delete } from 'react-iconly';
import { Space, Progress, Image } from 'antd';
import Typography from '@components/typography';
import { humanTimestamp } from '@utils/date.utils';
import KIconButton from '@components/k-icon-button';

type Props = {
  uri: String,
  filename: String,
  timestamp: firebase.firestore.Timestamp,
  progress: Number,
  uploading: Boolean,
};

const DocumentsCardRow = ({
  uri,
  filename,
  timestamp,
  progress,
  uploading,
  onDelete,
}: Props) => {
  return (
    <SadaDocumentsCardRow>
      <ImageIcon set="bulk" />
      <Image src={uri} />
      <Space direction="vertical" size={0}>
        <Typography
          onClick={() => {
            window.open(uri, '_blank');
          }}
        >
          {filename}
        </Typography>
        {uploading ? (
          <Progress percent={progress} />
        ) : (
          <Typography sm sub>
            Submitted {humanTimestamp(timestamp)}
          </Typography>
        )}
      </Space>
      {!uploading && (
        <KIconButton icon={<Delete />} danger onClick={onDelete} />
      )}
    </SadaDocumentsCardRow>
  );
};

export default DocumentsCardRow;
