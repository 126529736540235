import Typography from '@components/typography';
import useModal from '@hooks/modal.hook';
import { extractFormValues } from '@utils/form.utils';
import { Space } from 'antd';
import { SadaApplicatonAnswersModal } from './styles';

const ApplicatonAnswersModal = () => {
  const { visible, close, data } = useModal('answers');
  const answers = data?.form && extractFormValues(data.form);

  return (
    <SadaApplicatonAnswersModal
      title="Form Answers"
      visible={visible}
      footer={false}
      onCancel={close}
    >
      {answers && (
        <Space direction="vertical" size={40}>
          {answers.map(({ a, q, order }) => (
            <Space direction="vertical" size={16} key={order}>
              <Typography>
                {order + 1}. {a}
              </Typography>
              <Typography sub>{q}</Typography>
            </Space>
          ))}
        </Space>
      )}
    </SadaApplicatonAnswersModal>
  );
};

export default ApplicatonAnswersModal;
