import { useStore } from 'react-context-hook';

const useModal = (active): [String, any, Function] => {
  const [name, setName] = useStore('modal', null);
  const [data, setData] = useStore('data', null);
  const open = (data) => {
    setName(active);
    setData(data);
  };
  const close = () => {
    setName(null);
    setData(null);
  };
  return {
    name,
    setName,
    data,
    setData,
    visible: active === name,
    open,
    close,
  };
};

export default useModal;
