import Typography from '@components/typography';
import { UISpaceBox } from '@components/ui/layout';
import UserMenu from '@components/user-menu';
import { FormAppointmentBlock, SadaApplicantFormAppointment } from './styles';
import type IService from '@types/service.type';
import { getDisabledDate } from '@utils/date.utils';
import { Space } from 'antd';
import KTimePicker from '@components/k-time-picker';
import KCalendar from '@components/k-calendar';
import useApplyForm from '@hooks/apply-form.hook';

type Props = { service: IService };

const ApplicantFormAppointment = ({ service }: Props) => {
  const disabled = getDisabledDate(service?.schedule);
  const { data, setSchedule } = useApplyForm();
  const schedule = data?.schedule;
  return (
    <SadaApplicantFormAppointment>
      <Space direction="vertical" size={24}>
        <UISpaceBox>
          <Typography xl bold>
            Schedule an appointment
          </Typography>
          <UserMenu />
        </UISpaceBox>
        <FormAppointmentBlock direction="vertical">
          <Typography lg bold>
            1.Select date
          </Typography>
          <KCalendar
            fullscreen={false}
            disabledDate={disabled}
            value={schedule?.date}
            onChange={async (date) => {
              await setSchedule({ date });
            }}
          />
        </FormAppointmentBlock>
        <FormAppointmentBlock direction="vertical">
          <Typography lg bold>
            2.Select time slot
          </Typography>
          {schedule?.date ? (
            <KTimePicker
              service={service}
              date={schedule?.date}
              selected={schedule?.start}
              onSelect={async (v) => {
                await setSchedule({ ...schedule, ...v });
              }}
            />
          ) : (
            <Typography sub>Select Date first</Typography>
          )}
        </FormAppointmentBlock>
      </Space>
    </SadaApplicantFormAppointment>
  );
};

export default ApplicantFormAppointment;
