import functionsService from '@services/functions.service';
import { useEffect, useState } from 'react';

export const useUpload = ({
  file,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const fileName = file?.name;
  const upload = async (file) => {
    try {
      setUploading(true);
      const { url, id } = await functionsService.getSignedUrl();
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.upload.onprogress = ({ loaded, total }) => {
        setProgress(Math.round((loaded / total) * 100));
      };
      xhr.onload = async () => {
        const { status } = xhr;
        if (status === 200) {
          const link = await functionsService.getMediaLink(id);
          onSuccess({ ...xhr, uid: id, link });
          setUploading(false);
          setProgress(0);
        } else {
          setUploading(false);
          onError(new Error('Something went wrong'));
        }
      };

      xhr.onerror = (error) => {
        onError(error);
      };
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    } catch (error) {
      setUploading(false);
      onError(error);
    }
  };
  useEffect(() => {
    if (file) {
      upload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);
  return { uploading, progress };
};
