import functionsService from '@services/functions.service';

export const uploadFile = async ({
  action,
  onProgress,
  onSuccess,
  onError,
  file,
}) => {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', action.url, true);
  xhr.upload.onprogress = ({ loaded, total }) => {
    onProgress({ percent: (loaded / total) * 100 }, file);
  };
  xhr.onload = async () => {
    const { status } = xhr;
    if (status === 200) {
      const link = await functionsService.getMediaLink(action?.id);
      xhr.url = link;
      onSuccess(xhr, file);
    } else {
      onError();
    }
  };

  xhr.onerror = (error) => {
    onError(error);
  };
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.send(file);
};
