import { statusColors } from '@constants/theme/colors.config';
import styled from '@emotion/styled';
import { getStylebooleanProp } from '@utils/style.utils';
import tinycolor from 'tinycolor2';
import * as R from 'ramda';
import { spacings } from '@constants/theme/spacing.config';
import { radiuses } from '@constants/theme/radius.config';

const getColor = R.ifElse(
  R.prop('color'),
  R.pipe(R.prop('color'), R.prop(R.__, statusColors)),
  getStylebooleanProp(
    ['danger', 'warning', 'success'],
    statusColors,
    'neutral',
  ),
);

export const SadaStatusTag = styled.div`
  display: inline-block;
  background: ${R.ifElse(
    R.prop('flat'),
    R.always('none'),
    R.pipe(getColor, tinycolor, (v) => v.setAlpha(0.1).toRgbString()),
  )};
  padding: 0 ${spacings.sm};
  text-transform: uppercase;
  color: ${getColor};
  border-radius: ${radiuses.sm};
  ${({ large }) =>
    large
      ? `
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  `
      : ''}
`;
