import styled from '@emotion/styled';

export const SadaKSelect = styled.div`
  min-width: 100px;
  > * {
    width: 100%;
  }
  .ant-select-arrow {
    top: 18px;
  }
`;
