import * as R from 'ramda';
import { humanDate, humanTime } from './date.utils';
import { invoke } from './ramda.utils';

export const getCaseContentString = R.pipe(
  R.juxt([
    R.prop('type'),
    R.pipe(
      R.prop('start'),
      R.when(R.identity, R.pipe(invoke('toDate'), humanDate)),
    ),
    R.pipe(
      R.juxt([
        R.pipe(
          R.prop('start'),
          R.when(R.identity, R.pipe(invoke('toDate'), humanTime)),
        ),
        R.pipe(
          R.prop('end'),
          R.when(R.identity, R.pipe(invoke('toDate'), humanTime)),
        ),
      ]),
      R.join(' - '),
    ),
  ]),
  R.join(' | '),
);

export const countCasesByStatus = R.pipe(
  R.groupBy(R.path(['status', 'status'])),
  R.map(R.length),
);
