import ApplicantQuestionsForm from '@components/forms/applicant-questions-form';
import useData from '@hooks/data.hook';
import useOrg from '@hooks/org.hook';
import { SadaApply } from './styles';

type Props = { service_id: String };

const Apply = ({ service_id }: Props) => {
  const [service, loading] = useData('services', service_id);
  const [org, orgLoading] = useOrg('org', service_id);
  return (
    <SadaApply>
      <ApplicantQuestionsForm
        loading={loading && orgLoading}
        service={service}
        organization={org}
      />
    </SadaApply>
  );
};

export default Apply;
