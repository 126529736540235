import type IAvailability from '@types/availability.type';

const { default: functionsService } = require('@services/functions.service');
const { useState, useEffect } = require('react');

const useAvailability = (id, date): [IAvailability, Boolean] => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const getData = async (id, date) => {
    if (id && date) {
      setLoading(true);
      const data = await functionsService.getAvailability(date, id);
      setResult(data || []);
      setLoading(false);
    } else {
      setResult([]);
    }
  };
  useEffect(() => {
    getData(id, date);
  }, [id, date]);
  return [result, loading];
};

export default useAvailability;
