import styled from '@emotion/styled';
import { UIFlexBox } from '@components/ui/layout';
import { spacings } from '@constants/theme/spacing.config';
import screens from '@constants/theme/screens.config';

export const SadaHeaderDesktop = styled(UIFlexBox)`
  padding: 0 ${spacings.xxxl};
  align-items: center;
  justify-content: space-between;
`;
export const SadaHeaderMobile = styled(UIFlexBox)`
  display: none;
  align-items: center;
  padding: 0 ${spacings.xl};
`;

export const SadaHeader = styled(UIFlexBox)`
  ${SadaHeaderDesktop.toString()} {
    display: flex;
    width: 100%;
  }
  ${screens.mobile} {
    ${SadaHeaderDesktop.toString()} {
      display: none;
    }
    ${SadaHeaderMobile.toString()} {
      display: flex;
    }
  }
`;
