import { Divider, Input, message, Tabs } from 'antd';
import firebase from 'firebase';
import { useState } from 'react';
import { SadaLoginForm } from './styles';
import GoogleLoginButton from '../google-login-button';
import KForm from '@components/k-form';
import { useForm } from 'antd/lib/form/Form';
import KInput from '@components/k-input';
import KBtn from '@components/k-btn';
import { emailPattern } from '@utils/form.utils';
import { useQueryParams } from 'hookrouter';
import React from 'react';

type Props = {
  authLoading: Boolean,
  disabled: Boolean,
  view: String,
};

const LoginForm = ({ authLoading, disabled, view = 'login' }: Props) => {
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const login = async () => {
    setLoading(true);
    try {
      await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [loginForm] = useForm();
  const [signupForm] = useForm();
  const loginWithPassword = async (v) => {
    setLoginLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(v.email, v.password);
    } catch (error) {
      message.error(
        {
          content: error.message,
          onClick: () => {
            message.destroy();
          },
        },
        10000,
      );
    } finally {
      setLoginLoading(false);
    }
  };
  const [, setQueryParams] = useQueryParams();

  const signupWithPassword = async (v) => {
    setLoginLoading(true);
    try {
      setQueryParams({ name: v.name });
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(v.email, v.password);
      await user.updateProfile({ displayName: v.name });
    } catch (error) {
      message.error(
        {
          content: error.message,
          onClick: () => {
            message.destroy();
          },
        },
        10000,
      );
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <SadaLoginForm>
      {!disabled && (
        <>
          <Tabs defaultActiveKey={view} centered>
            <Tabs.TabPane tab="Log In" key="login">
              <KForm
                form={loginForm}
                onFinish={loginWithPassword}
                layout="vertical"
                fields={[
                  {
                    placeholder: 'Email',
                    name: 'email',
                    fieldKey: 'email',
                    rules: [{ required: true, message: 'Email is required' }],
                    validateTrigger: 'onBlur',
                    component: KInput,
                    wrapperCol: { xs: 24 },
                    componentProps: { placeholder: 'Email' },
                  },
                  {
                    placeholder: 'Password',
                    name: 'password',
                    fieldKey: 'password',
                    rules: [
                      { required: true, message: 'Password is required' },
                    ],
                    validateTrigger: 'onBlur',
                    component: Input.Password,
                    wrapperCol: { xs: 24 },
                    componentProps: { placeholder: 'Password' },
                  },
                ]}
              />
              <KBtn block onClick={loginForm.submit} loading={loginLoading}>
                Log In
              </KBtn>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Sign Up" key="signup">
              <KForm
                form={signupForm}
                onFinish={signupWithPassword}
                layout="vertical"
                fields={[
                  {
                    placeholder: 'Name',
                    name: 'name',
                    fieldKey: 'name',
                    validateTrigger: 'onBlur',
                    component: KInput,
                    wrapperCol: { xs: 24 },
                    rules: [
                      { required: true, message: 'Full Name is required' },
                    ],
                    componentProps: { placeholder: 'Full Name' },
                  },
                  {
                    rules: [
                      { required: true, message: 'Email is required' },
                      {
                        pattern: emailPattern,
                        message: 'Invalid Email',
                      },
                    ],
                    wrapperCol: { xs: 24 },
                    validateTrigger: 'onBlur',
                    fieldKey: 'email',
                    name: 'email',
                    component: KInput,
                    componentProps: { placeholder: 'Email' },
                  },
                  // {
                  //   rules: [
                  //     {
                  //       pattern: phonePattern,
                  //       message: 'Invalid Phone',
                  //     },
                  //   ],
                  //   fieldKey: 'phone',
                  //   wrapperCol: { xs: 24 },
                  //   name: 'phone',
                  //   validateTrigger: 'onBlur',
                  //   component: MaskedInput,
                  //   componentProps: {
                  //     mask: '(111) 111-1111',
                  //     placeholder: 'Phone number',
                  //   },
                  // },
                  {
                    placeholder: 'Password',
                    name: 'password',
                    fieldKey: 'password',
                    validateTrigger: 'onBlur',
                    component: Input.Password,
                    rules: [
                      { required: true, message: 'Password is required' },
                    ],
                    wrapperCol: { xs: 24 },
                    componentProps: { placeholder: 'Password' },
                  },
                ]}
              />
              <KBtn block onClick={signupForm.submit} loading={loginLoading}>
                Create Account
              </KBtn>
            </Tabs.TabPane>
          </Tabs>
          <Divider>or</Divider>
        </>
      )}
      <GoogleLoginButton onClick={login} loading={loading || authLoading} />
    </SadaLoginForm>
  );
};

export default LoginForm;
