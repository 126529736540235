import { Select } from 'antd';
import { SadaKSelect } from './styles';
import { SelectProps } from 'antd';
import { ChevronDown } from 'react-iconly';

type Props = SelectProps & {
  options: [{ key: String, label: String }],
};

const KSelect = ({ ...props }: Props) => (
  <SadaKSelect>
    <Select {...props} {...(props.loading ? {} : { suffixIcon: <ChevronDown size="small" /> })} />
  </SadaKSelect>
);

export default KSelect;
