import { UIBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { Col, Row } from 'antd';
import { SadaCases } from './styles';
import { spacings } from '@constants/theme/spacing.config';

import { navigate } from 'hookrouter';
import useAuth from '@hooks/auth.hook';
import fsOrm from '@services/firestore.service';
import useOrg from '@hooks/org.hook';
import CaseList from '@components/lists/case-list';
import useList from '@hooks/list.hook';
import MeetCardWrapper from '@components/meet-card-wrapper';
import ShowMobile from '@components/show-mobile';
import KHeading from '@components/k-heading';

const Cases = () => {
  const [user] = useAuth();
  const [org, orgLoading] = useOrg();
  const [cases = [], loading] = useList('cases', {
    where: ['applicant', '==', fsOrm.applicants(user.email).ref()],
  });

  return (
    <SadaCases>
      <ShowMobile>
        <KHeading xl title="Appointments" />
      </ShowMobile>
      <Row gutter={40}>
        <Col sm={14}>
          <UIBox
            borderTop={`1px solid ${colors.almost_white}`}
            pt={spacings.xs}
          >
            <CaseList
              cases={cases}
              organization={org}
              loading={loading || orgLoading}
              onClick={(id) => {
                navigate(`/cases/${id}`);
              }}
              emptyText="There are no upcoming appointments"
            />
          </UIBox>
        </Col>
        <Col sm={10}>
          {user?.email && (
            <MeetCardWrapper
              applicant={user.email}
              emptyText="You have no upcoming appointments"
            />
          )}
        </Col>
      </Row>
    </SadaCases>
  );
};

export default Cases;
