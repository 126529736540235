import { SadaUserCard } from '@components/cards/user-card/styles';
import screens from '@constants/theme/screens.config';
import styled from '@emotion/styled';

export const SadaDashboard = styled.div`
  .ant-space {
    width: 100%;
  }
  ${SadaUserCard.toString()} {
    margin-top: 72px;
    ${screens.mobile} {
      margin-top: 0;
    }
  }
`;
