import { type IMenuItem } from '@/types/menu.type';
import AccountSettings from '@applicant/components/account-settings';
import Case from '@applicant/components/cases/case';

import { Category, Setting, Work } from 'react-iconly';
import Cases from '../components/cases';
import Dashboard from '../components/dashboard';

const applicantRoutesConfig: [IMenuItem] = {
  '/': {
    component: Dashboard,
    label: 'Dashboard',
    path: '/',
    icon: Category,
  },
  '/cases': {
    component: Cases,
    label: 'Appointments',
    path: '/cases',
    icon: Work,
  },
  '/cases/:id': {
    component: Case,
    label: 'Appointments',
    path: '/cases',
    hideMenu: true,
    match: /\/cases\/(?<id>\w+)/,
  },
  '/account': {
    component: AccountSettings,
    label: 'Account Settings',
    path: '/account',
    icon: Setting,
    divider: true,
  },
};

export default applicantRoutesConfig;
