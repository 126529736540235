import Typography from '@components/typography';
import { caseStatuses } from '@constants/status.config';
import { SadaStatusTag } from './styles';

type Props = {
  danger: Boolean,
  warning: Boolean,
  neutral: Boolean,
  success: Boolean,
  text: String,
  md: Boolean,
  flat: Boolean,
  color: String,
  large: Boolean,
  status: String,
};

const StatusTag = ({ text, md, status, ...props }: Props) => (
  <SadaStatusTag {...props} {...caseStatuses[status]}>
    <Typography bold md={md}>
      {caseStatuses[status]?.text || text}
    </Typography>
  </SadaStatusTag>
);

export default StatusTag;
