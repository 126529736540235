import { SadaKForm } from './styles';
import { FormProps, FormItemProps, Form, Input } from 'antd';
import KInput from '@components/k-input';
import KFormList from './k-form-list';
import { Element } from 'react';

type Field = FormItemProps & {
  componentProps: Object,
  list: Boolean,
  component: Element,
  group: Boolean,
  fields: Field[],
};
type Props = FormProps & {
  fields: Field[],
  idKey: String,
};

const layout = {
  labelCol: { xs: 7 },
  wrapperCol: { xs: 17 },
  labelAlign: 'left',
};

const KForm = ({ fields = [], idKey = 'id', ...props }: Props) => (
  <SadaKForm {...layout} {...props}>
    {fields.map(
      ({
        component: Component,
        componentProps,
        list,
        group,
        fields,
        ...field
      }) =>
        group ? (
          <Form.Item label={field.label} key={field.name}>
            <Input.Group compact>
              {fields?.map((f) => (
                <Form.Item {...f} key={f.name} noStyle>
                  {f.component ? (
                    <f.component {...f.componentProps} />
                  ) : (
                    <KInput {...f.componentProps} />
                  )}
                </Form.Item>
              ))}
            </Input.Group>
          </Form.Item>
        ) : list ? (
          <KFormList field={field} form={props.form} key={field.name} />
        ) : (
          <Form.Item {...field} key={field.name}>
            {Component ? <Component {...componentProps} /> : <KInput />}
          </Form.Item>
        ),
    )}
    <div style={{ display: 'none' }}>
      <Form.Item name={idKey}>
        <Input />
      </Form.Item>
    </div>
  </SadaKForm>
);

export default KForm;
