import { motion } from 'framer-motion';

export const SlideIn: FC = (props) => (
  <motion.div
    {...props}
    initial={{ x: 100, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: -100, opacity: 0 }}
    transition={{ type: 'tween', duration: 0.1 }}
  />
);

export const ListItemIn: FC = (props) => (
  <motion.div
    {...props}
    layout
    transition={{
      type: 'spring',
      damping: 25,
      stiffness: 400,
    }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ x: 50, opacity: 0 }}
    initial={{ y: -50, opacity: 0 }}
  />
);

export const FadeZoomIn: FC = (props) => (
  <motion.div
    {...props}
    layout
    transition={{
      type: 'spring',
      damping: 25,
      stiffness: 400,
    }}
    animate={{ transform: 'scale(1)', opacity: 1 }}
    exit={{ transform: 'scale(0.9)', opacity: 0 }}
    initial={{ transform: 'scale(0.9)', opacity: 0 }}
  />
);

export const FadeDropIn: FC = (props) => (
  <motion.div
    {...props}
    layout
    transition={{
      type: 'spring',
      damping: 25,
      stiffness: 400,
    }}
    animate={{ top: 0, opacity: 1 }}
    exit={{ top: -10, opacity: 0 }}
    initial={{ top: -10, opacity: 0 }}
  />
);
