import { Space, Spin } from 'antd';
import { SadaLogin, SadaLoginBox } from './styles';
import useOrg from '@hooks/org.hook';
import LoginForm from './login-form';

type Props = {
  authLoading: Boolean,
  disabled: Boolean,
};

const Login = ({ authLoading, disabled }: Props) => {
  const [org, orgLoading] = useOrg();

  return (
    <SadaLogin>
      <SadaLoginBox>
        <Spin spinning={orgLoading}>
          <Space direction="vertical" size={48}>
            <Space direction="vertical">
              {org && (
                <img
                  src={org.logo}
                  style={{ maxWidth: '100%' }}
                  alt="VAST Logo"
                />
              )}
            </Space>
            <LoginForm disabled={disabled} />
          </Space>
        </Spin>
      </SadaLoginBox>
    </SadaLogin>
  );
};

export default Login;
