import { colors } from '@constants/theme/colors.config';
import styled from '@emotion/styled';
import { getStylebooleanProp } from '@utils/style.utils';

const iconColors = {
  dark: colors.almost_black,
  danger: colors.red,
  blue: colors.blue,
};

const iconSize = {
  md: '24px',
  sm: '20px',
};

export const SadaKIconButton = styled.div`
  border-radius: 50%;
  cursor: pointer;
  &,
  & svg {
    width: ${getStylebooleanProp(['md', 'sm'], iconSize, 'md')};
    height: ${getStylebooleanProp(['md', 'sm'], iconSize, 'md')};
  }
  color: ${getStylebooleanProp(['dark', 'danger'], iconColors, 'blue')};
  ${({ disabled }) =>
    disabled
      ? `
    pointer-events: none;
    color: ${colors.light_gray};
  `
      : ''}
  ${({ bg }) =>
    bg
      ? `
      background: #FFF;
  `
      : ''}
`;
