import ApplicantCard from '@components/cards/applicant-card';
import MeetCard from '@components/cards/meet-card';
import useAuth from '@hooks/auth.hook';
import useData from '@hooks/data.hook';
import useModal from '@hooks/modal.hook';
import { Col, Row } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { SadaAccountSettings } from './styles';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import * as R from 'ramda';
import HideMobile from '@components/hide-mobile';

const AccountSettings = () => {
  const [user] = useAuth();
  const { open } = useModal('profile');
  const [applicant, loading] = useData('applicants', user?.email);

  const [appointments = []] = useCollectionData(
    firebase
      .firestore()
      .collection('appointments')
      .where('applicant', '==', fsOrm.applicants(user.email).ref()),
  );
  const sortedAppts = R.pipe(
    R.sortBy(R.prop('start')),
    R.reverse,
  )(appointments);
  const meetUrl = sortedAppts?.[0]?.meetUrl;
  return (
    <SadaAccountSettings>
      <Row gutter={40}>
        <Col xs={24} sm={14}>
          <ApplicantCard
            title="Your Information"
            loading={loading}
            applicant={applicant}
            onAction={() => {
              open(applicant);
            }}
          />
        </Col>
        <Col xs={24} sm={10}>
          <HideMobile>
            <MeetCard code={meetUrl} />
          </HideMobile>
        </Col>
      </Row>
    </SadaAccountSettings>
  );
};

export default AccountSettings;
