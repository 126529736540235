import KListCard, { type KListCardProps } from '@components/cards/k-list-card';
import { SadaKCardList } from './styles';
import * as R from 'ramda';
import { Empty } from 'antd';

type Props = {
  data: Array,
  config: KListCardProps,
  idKey: String,
  emptyImage: Node,
  emptyText: String,
  loading: Boolean,
  flatMobile: Boolean,
  onClick: (String) => void,
  cardProps: Object,
};

const KCardList = ({
  data = [],
  config = {},
  idKey = 'id',
  emptyImage,
  emptyText,
  loading,
  flatMobile,
  cardProps,
  onClick,
}: Props) => {
  return (
    <SadaKCardList size={8} direction="vertical">
      {loading ? (
        new Array(3).map((i, ix) => <KListCard key={ix} id={ix} title loading subTitle content />)
      ) : data?.length ? (
        data?.map((row) => {
          console.log({ ...(cardProps ? cardProps : R.applySpec(config)(row)) });
          return (
            <KListCard
              flatMobile={flatMobile}
              onClick={onClick}
              key={row[idKey]}
              {...(cardProps ? cardProps : R.applySpec(config)(row))}
              id={row[idKey]}
            />
          );
        })
      ) : (
        <Empty image={emptyImage || Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
      )}
    </SadaKCardList>
  );
};

export default KCardList;
