import { useUpload } from '@hooks/upload.hook';
import { Progress } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useRef, useState } from 'react';
import { Edit } from 'react-iconly';
import { SadaKPhotoUpload, SadaKPhotoUploadHover } from './styles';

type Props = { onChange: Function, value: String };

const KPhotoUpload = ({ value, onChange = () => {} }: Props) => {
  const inputRef = useRef();
  const [file, setFile] = useState();
  const onSelect = async ({ target }) => {
    setFile(target.files[0]);
  };

  const onSuccess = ({ link }) => {
    onChange(link);
  };

  const { progress, uploading } = useUpload({ file, onSuccess });
  return (
    <SadaKPhotoUpload
      onClick={() => {
        if (!uploading) {
          inputRef.current.click();
        }
      }}
    >
      {uploading ? (
        <Progress type="circle" percent={progress} width={100} />
      ) : (
        <>
          <Avatar src={value} size={100} />
          <input
            type="file"
            ref={inputRef}
            onChange={onSelect}
            accept="image/png, .jpeg, .jpg, image/gif"
          />
          <SadaKPhotoUploadHover>
            <Edit />
          </SadaKPhotoUploadHover>
        </>
      )}
    </SadaKPhotoUpload>
  );
};

export default KPhotoUpload;
