import { getInitials } from '@utils/user.utils';
import { SadaKAvatar } from './styles';
import { AvatarProps } from 'antd';
import { Skeleton } from 'antd';

type Props = AvatarProps & {
  photo: String,
  name: String,
};

const KAvatar = ({ photo, name, loading, ...props }: Props) =>
  loading ? (
    <Skeleton title={false} paragraph={false} avatar={props} loading />
  ) : (
    <SadaKAvatar size="small" src={photo} {...props}>
      {getInitials(name)}
    </SadaKAvatar>
  );

export default KAvatar;
