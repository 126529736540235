import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Menu, Space } from 'antd';

export const SadaUserMenu = styled(Space)`
  flex-shrink: 0;
  .ant-space-item {
    height: 24px;
  }
  justify-content: flex-end;
`;

export const SadaUserMenuDropdown = styled(Menu)`
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${spacings.xs};
    }
  }
`;
