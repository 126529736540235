import DocumentsCardRow from './documents-card-row';
import { SadaDocumentsCard } from './styles';
import type IAttachment from '@types/attachment.type';
import * as R from 'ramda';

type Props = {
  docs: Array,
  uploading: { progress: Number, filename: String, uploading: Boolean },
  onChange: ([IAttachment]) => void,
};

const DocumentsCard = ({ docs = [], uploading, onChange }: Props) => (
  <SadaDocumentsCard title={`${docs.length} Documents`} noPadding>
    {docs.map((doc, idx) => (
      <DocumentsCardRow
        key={doc.uri}
        {...doc}
        onDelete={() => {
          onChange(R.remove(idx, 1, docs));
        }}
      />
    ))}
    {uploading?.uploading && <DocumentsCardRow {...uploading} />}
  </SadaDocumentsCard>
);

export default DocumentsCard;
