import { colors } from './colors.config';

export const fontSizes = {
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '24px',
  xxl: '38px',
};

export const lineSizes = {
  sm: '22px',
  md: '24px',
  lg: '24px',
  xl: '32px',
  xxl: '46px',
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const fontColors = {
  sub: colors.dark_gray,
  danger: colors.red,
};
