import * as R from 'ramda';

export const selectLeastBusy = (slots) =>
  R.pipe(
    R.prop('employees'),
    R.reduce(
      (acc, v) => ({
        ...acc,
        [v]: R.pipe(
          R.filter(R.propSatisfies(R.includes(v), 'employees')),
          R.length,
        )(slots),
      }),
      {},
    ),
    R.toPairs,
    R.reduce(R.maxBy(R.last), [0, -1]),
    R.head,
  );
