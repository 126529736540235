import { SadaMeetCardWrapper } from './styles';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import MeetCard from '@components/cards/meet-card';
import useFs from '@hooks/fs.hook';
import { startOfMinute, subMinutes } from 'date-fns';

type Props = {
  employee: String,
  applicant: String,
  emptyText: String,
  caseId: String,
};

const MeetCardWrapper = ({ employee, applicant, emptyText, caseId }: Props) => {
  let query = firebase.firestore().collection('appointments');

  if (employee) {
    query = query.where('employee', '==', fsOrm.employees(employee).ref());
  }
  if (applicant) {
    query = query.where('applicant', '==', fsOrm.applicants(applicant).ref());
  }
  if (caseId) {
    query = query.where('case', '==', fsOrm.cases(caseId).ref());
  }
  query = query
    .where('start', '>', subMinutes(startOfMinute(new Date()), 45))
    .orderBy('start', 'asc');
  const [data, loading] = useFs({ query });
  const [meet] = data || [];
  return (
    <SadaMeetCardWrapper>
      {!loading && <MeetCard code={meet?.meetUrl} start={meet?.start} emptyText={emptyText} />}
    </SadaMeetCardWrapper>
  );
};

export default MeetCardWrapper;
