import { SadaKFormModal } from './styles';
import { FormProps, ModalProps, FormItemProps } from 'antd';
import KModal from '../k-modal';
import KForm from '@components/k-form';
import useModal from '@hooks/modal.hook';
import { useEffect } from 'react';

type Props = {
  name: String,
  formProps: FormProps & { fields: [FormItemProps] },
  modalProps: ModalProps,
};

const KFormModal = ({ name, formProps, modalProps }: Props) => {
  const { visible, close, data } = useModal(name);
  useEffect(() => {
    if (visible) {
      formProps?.form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <SadaKFormModal>
      <KModal visible={visible} onCancel={close} {...modalProps}>
        <KForm
          preserve={false}
          initialValues={data}
          validateTrigger="onBlur"
          {...formProps}
        />
      </KModal>
    </SadaKFormModal>
  );
};

export default KFormModal;
