import { colors } from '@constants/theme/colors.config';
import { SadaAppointmentTimeTag } from './styles';
import { Tag } from 'antd';

type Props = {
  status: string,
};

const tagColors = {
  today: colors.orange,
  tomorrow: colors.green,
  now: colors.red,
};
const AppointmentTimeTag = ({ status }: Props) => (
  <SadaAppointmentTimeTag>
    <Tag color={tagColors[status]}>{status?.toUpperCase()}</Tag>
  </SadaAppointmentTimeTag>
);

export default AppointmentTimeTag;
